import axios, { AxiosError, AxiosInstance } from "axios";
import {
  SLS_API_URL,
  SLS_REPORTS_ENDPOINTS,
  SLS_USER_ENDPOINTS,
} from "../constants/apiEndpoints";
import { Dispatch } from "react";
import { AnyAction } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store";
import User, { UserGameData } from "../types/sls/User";
import { Referral } from "../types/Referral";
import { useUser } from "./useUser";
import Task from "../types/sls/Task";

export function getSlsAxiosInstnce(
  dispatch: Dispatch<AnyAction>,
  getAccessTokenSilently: () => Promise<string>
): AxiosInstance {
  const instance = axios.create({
    baseURL: `${SLS_API_URL}`,
  });

  instance.interceptors.request.use(
    async (config) => {
      const token = await getAccessTokenSilently();
      if (!token) {
        throw new axios.Cancel("Token missing");
      }
      config.headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      return config;
    },
    async (error) => {
      await Promise.reject(error);
    }
  );

  return instance;
}

function useSlsAxios() {
  const dispatch = useDispatch<AppDispatch>();
  const { getAccessTokenSilently } = useUser();

  return getSlsAxiosInstnce(dispatch, getAccessTokenSilently);
}

function useSls(axiosInstance?: AxiosInstance) {
  const slsAxiosInstance = useSlsAxios();
  const slsAxios = axiosInstance ?? slsAxiosInstance;

  const getUser = async ({ id }: { id: string | number }) => {
    if (!id) {
      throw new Error("Id param missing");
    }

    const response = await slsAxios.get<{ item: User }>(
      SLS_USER_ENDPOINTS.GET_USER(id)
    );
    return response.data.item;
  };

  const updateUser = async ({
    id,
    user,
  }: {
    id: string;
    user: User;
  }): Promise<User> => {
    const response = await slsAxios.put<{ user: User }>(
      SLS_USER_ENDPOINTS.PUT_USER(id),
      user
    );
    return response.data.user;
  };

  interface getUsersInput {
    lastId?: string;
    email?: string;
    ethWallet?: string;
  }
  const getUsers = async ({ lastId, email, ethWallet }: getUsersInput) => {
    const usersResponse = await slsAxios.get<{
      items: { id: string; ethWallet: string; email: string }[];
      lastId: string;
    }>(SLS_USER_ENDPOINTS.GET_USERS(lastId, email, ethWallet));
    return usersResponse.data;
  };

  const addUser = async ({
    accountType,
    email,
    name,
  }: {
    accountType: string;
    email: string;
    name: string;
  }) => {
    return await slsAxios.post(SLS_USER_ENDPOINTS.POST_USER, {
      accountType,
      email,
      name
    });
  };

  const getHoneypotUsers = async () => {
    const response = await slsAxios.get(SLS_USER_ENDPOINTS.GET_HONEYPOT_USERS);
    return response.data.items;
  };

  const getUserHero = async ({ id }: { id: string }): Promise<UserGameData> => {
    if (!id) {
      throw new Error("Id param missing");
    }

    const response = await slsAxios.get<{ item: { gameData: UserGameData } }>(
      SLS_USER_ENDPOINTS.GET_USER_HERO(id)
    );
    return response?.data?.item?.gameData;
  };

  const getUserDiscord = async ({ id }: { id: string | number }) => {
    if (!id) {
      throw new Error("Id param missing");
    }

    try {
      const response = await slsAxios.get<{
        discordUsername: string;
        discordId: string;
      }>(SLS_USER_ENDPOINTS.GET_USER_DISCORD_USERNAME(id));

      return response.data;
    } catch (e) {
      if (e instanceof AxiosError) {
        throw new Error(e?.response?.data ?? "Cannot get user discord.");
      }

      throw new Error("Cannot get user discord.");
    }
  };

  const editUserDiscord = async ({
    id,
    discordId,
    discordUsername,
  }: {
    id: string;
    discordId: string | null;
    discordUsername: string | null;
  }) => {
    return await slsAxios.patch(
      SLS_USER_ENDPOINTS.PATCH_USER_DISCORD_USERNAME(id),
      {
        discordId,
        discordUsername,
      }
    );
  };
  interface ModifyPathInput {
    userId: string;
    fullPathLevelId: string;
    action: "abortPathLevel" | "addPathLevel" | "completePathLevel";
    message?: string;
  }
  const modifyPath = async ({
    userId,
    fullPathLevelId,
    action,
    message,
  }: ModifyPathInput): Promise<User> => {
    const response = await slsAxios.put<{ item: User }>(
      SLS_USER_ENDPOINTS.MODIFY_ACTIVE_PATH(userId),
      {
        fullPathLevelId,
        action,
        message,
        useTaskAutomation: false,
      }
    );

    return response?.data?.item;
  };

  interface ModifyWorldInput {
    userId: string;
    worldName: string;
    action: "addWorld" | "removeWorld";
  }
  const modifyWorld = async ({
    userId,
    worldName,
    action,
  }: ModifyWorldInput): Promise<User> => {
    const response = await slsAxios.put<{ item: User }>(
      SLS_USER_ENDPOINTS.MODIFY_WORLD(userId),
      {
        worldName,
        action,
      }
    );

    return response?.data?.item;
  };

  const recalculatePathsAndWorlds = async ({
    userId,
  }: {
    userId: string;
  }): Promise<User> => {
    const response = await slsAxios.put<{ item: User }>(
      SLS_USER_ENDPOINTS.RECALCULATE_PATHS_AND_WORLDS(userId)
    );
    return response?.data?.item;
  };

  const forceRefreshDiscordRoles = async ({ userId }: { userId: string }) => {
    await slsAxios.post(SLS_USER_ENDPOINTS.FORCE_REFRESH_DISCORD_ROLES(userId));
  };

  const removeSkill = async ({
    fullPathId,
    userId,
  }: {
    fullPathId: string;
    userId: string;
  }): Promise<User> => {
    const response = await slsAxios.put<{ item: User }>(
      SLS_USER_ENDPOINTS.REMOVE_SKILLS(userId),
      {
        skillId: fullPathId,
      }
    );
    return response.data.item;
  };

  const getStudentsReport = async (date: string) => {
    const response = await slsAxios.get<{ result: {} }>(
      SLS_REPORTS_ENDPOINTS.GET_STUDENTS_REPORT(date)
    );
    return response.data.result;
  };

  const modifyExp = async ({
    userId,
    exp,
  }: {
    userId: string;
    exp: number;
  }): Promise<User> => {
    const response = await slsAxios.put<{ item: User }>(
      SLS_USER_ENDPOINTS.MODIFY_EXP(userId),
      {
        exp,
      }
    );
    return response.data.item;
  };

  const getReflinksReport = async () => {
    const response = await slsAxios.get<{ data: Referral[] }>(
      SLS_REPORTS_ENDPOINTS.GET_REFLINKS_REPORT
    );
    return response.data.data;
  };

  const getAvailableWorlds = async (userId: string) => {
    const response = await slsAxios.get<{
      worlds: {
        heid: string;
        testMode: boolean;
        displayName: string;
      }[];
    }>(SLS_USER_ENDPOINTS.GET_AVAILABLE_WORLDS(userId));
    return response.data.worlds;
  };

  const getTasksForPathLevelId = async (
    pathLevelId: string,
    userId?: string
  ): Promise<Task[]> => {
    const response = await slsAxios.get<{ tasks: Task[] }>(
      SLS_USER_ENDPOINTS.GET_TASKS_FOR_PATH_LEVEL(pathLevelId, userId || "")
    );
    return response.data.tasks;
  };

  const generateAnswerRequestUrl = async (
    userId: string,
    taskHeid: string
  ): Promise<string> => {
    const response = await slsAxios.post<{ answerRequestId: string }>(
      SLS_USER_ENDPOINTS.GET_ANSWER_REQUEST,
      {
        taskHeid,
        userId,
      }
    );
    return response.data.answerRequestId;
  };

  const resendTask = async (userId: string, taskHeid: string) => {
    const respone = await slsAxios.get(
      SLS_USER_ENDPOINTS.GET_RESEND_EMAIL(userId, taskHeid)
    );
    return respone.status === 200;
  };

  const removeTask = async (userId: string, taskHeid: string): Promise<User> => {
    const respone = await slsAxios.delete<{ item: User }>(
      SLS_USER_ENDPOINTS.DELETE_REMOVE_TASK(userId, taskHeid)
    );
    return respone.data?.item;
  };
  const addEduRecord = async (userId: string, {title, description, amount, when}:
                                {title: string, description?: string, amount: string, when: Date}) : Promise<User> => {
    const response = await slsAxios.post<{ item: User }>(
      SLS_USER_ENDPOINTS.POST_EDU_RECORD(userId),{
        useGenesisMultiplier: true,
        when: when.toISOString(),
        title,
        description,
        amount
      });

    return response.data.item;
  }

  const addTask = async (
    userId: string,
    taskHeid: string
  ): Promise<{ success: boolean; message?: string }> => {
    try {
      const respone = await slsAxios.post(
        SLS_USER_ENDPOINTS.POST_ADD_TASK(userId),
        {
          taskHeid,
        }
      );
      return { success: respone.status === 200 };
    } catch (e) {
      if (axios.isAxiosError(e)) {
        return {
          success: false,
          message: e?.response?.data as string,
        };
      }
    }
    return { success: false };
  };

  const getObjectChildren = async (
    key: string
  ): Promise<
    | {
        id: string;
        pId: string;
        value: string;
        title: string;
        isLeaf: boolean;
      }[]
    | null
  > => {
    try {
      const response = await slsAxios.get<{
        result: {
          id: string;
          pId: string;
          value: string;
          title: string;
          isLeaf: boolean;
        }[];
      }>(`utils/get-object-children?key=${encodeURIComponent(key)}`);
      return response.data.result;
    } catch (e) {
      console.log(e);
    }
    return null;
  };

  const getGenesisForUser = async (userId: string): Promise<any[] & { type: "gold" | "normal" } | null> => {
    const response =
      await slsAxios.get<{nfts:(any[] & { type: "gold" | "normal" }) | null}>(
        SLS_USER_ENDPOINTS.GET_GENESIS_FOR_USER(userId)
      );
    if(response.data?.nfts) {
      return response.data.nfts;
    }else{
      return null;
    }
  }


  return {
    getUser,
    getUsers,
    getUserDiscord,
    editUserDiscord,
    updateUser,
    addUser,
    getHoneypotUsers,
    getUserHero,
    modifyPath,
    modifyWorld,
    recalculatePathsAndWorlds,
    forceRefreshDiscordRoles,
    removeSkill,
    getStudentsReport,
    modifyExp,
    getReflinksReport,
    getAvailableWorlds,
    getTasksForPathLevelId,
    generateAnswerRequestUrl,
    resendTask,
    addTask,
    getObjectChildren,
    removeTask,
    addEduRecord,
    getGenesisForUser
  };
}

export default useSls;
