import { useEffect, useState } from "react"
import { Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ROUTE_ADMIN_PANEL } from "../../../constants/routes";
import useSls from "../../../hooks/useSls"
import BsButton from "../../Common/Button";
import Loader from "../../Loader";

export const ListHoneypotUsers = () => {
  const { getHoneypotUsers } = useSls();

  const [isLoading, setIsLoading] = useState(true);
  const [honeypotUsers, setHoneypotUsers] = useState<{id: string; ethWallet: string;}[] | null>(null);

  useEffect(() => {
    setIsLoading(true);
    getHoneypotUsers()
      .then(users => {
        setHoneypotUsers(users);
        setIsLoading(false);
      });
  }, [])

  return (
    <>
      {
        isLoading ?
          <Loader /> :
          <>
            <Row className='pb-3 pt-3'>
              <Col xs={3}><Link to={ROUTE_ADMIN_PANEL}><BsButton>Back to admin panel</BsButton></Link></Col>
            </Row>
            <Row>
              <Col>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>EhtWalletAddress</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      honeypotUsers && honeypotUsers.map((u, i) => (
                        <tr key={i}>
                          <td>{i}</td>
                          <td>{u.ethWallet}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
      }
    </>
  )
}
