export const ROUTE_HOME = "/";
export const ROUTE_LOGIN_SUCCESS = "/login-success";
export const ROUTE_ADMIN_PANEL = "/admin";
export const ROUTE_ADMIN_PANEL_USER = "/admin/user/:id";
export const ROUTE_ADMIN_PANEL_USER_WITH_WALLET = (walletEth: string): string =>
  ROUTE_ADMIN_PANEL_USER.replace(":id", walletEth);
export const ROUTE_ADMIN_PANEL_ADD_USER = "/admin/add-user";
export const ROUTE_ADMIN_PANEL_HONEYPOT_USERS = "/admin/honeypot";
export const ROUTE_ADMIN_PANEL_REPORTS = "/admin/reports";
export const ROUTE_ADMIN_PANEL_REPORT_STUDENTS = "/admin/reports/students";
export const ROUTE_ADMIN_PANEL_REPORT_REFLINKS = "/admin/reports/reflinks";
