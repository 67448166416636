import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loginUser, logOut } from "../../redux/slices/userSlice";
import { AppDispatch } from "../../redux/store";
import { ROUTE_ADMIN_PANEL, ROUTE_HOME } from "../../constants/routes";
import Loader from "../Loader";
import { useUser } from "../../hooks/useUser";

export const LoginSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { getAccessTokenSilently, isAuthenticated, logout } = useUser();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isAuthenticated) {
      setIsLoading(true);
      return;
    }
    const f = async () => {
      await dispatch(loginUser({ getAccessTokenSilently }));
    };
    f()
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        dispatch(logOut());
        logout();
        navigate(ROUTE_HOME);
      });
  }, [isAuthenticated, dispatch, getAccessTokenSilently]);

  useEffect(() => {
    if (!isLoading) {
      navigate(ROUTE_ADMIN_PANEL);
    }
  }, [isLoading]);

  return <Loader />;
};
