import { Navigate } from "react-router-dom";
import { ROUTE_HOME } from "../../../constants/routes";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/selectors/userSelector";
import { useUser } from "../../../hooks/useUser";
interface AdminProtectedProps {
  redirect?: boolean;
  children: JSX.Element;
}

const AdminProtected = (props: AdminProtectedProps) => {
  const user = useSelector(selectUser);
  const { logout } = useUser();

  if (!user) {
    logout();
    if (props.redirect) {
      return <Navigate to={ROUTE_HOME} replace />;
    }

    return <></>;
  }

  if (user?.claims.isAdmin) {
    return props.children;
  }

  if (props.redirect) {
    logout();
    return <Navigate to={ROUTE_HOME} replace />;
  }

  return <></>;
};

export default AdminProtected;
