import {useCallback, useState} from "react";

export function useCopyToClipboard() {
  const [isCopied, setCopied] = useState(false);

  const copy = useCallback((text: string) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text);
      setCopied(true);
    }
  }, []);

  return [isCopied, copy] as const;
}