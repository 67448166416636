import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import useSls from "../../../../hooks/useSls";
import { toast } from "react-toastify";
import Loader from "../../../Loader";
import BsButton from "../../../Common/Button";

export const Students = () => {
  const { getStudentsReport } = useSls();
  const [results, setResults] = useState<{} | null>(null);
  const [selectedReportDate, setSelectedReportDate] = useState<string>(
    new Date().toISOString().split("T")[0]
  );
  const [loading, setLoading] = useState(false);

  const getReport = async () => {
    setResults(null);
    setLoading(true);
    if (!selectedReportDate) {
      return;
    }
    try {
      const reportDate = await getStudentsReport(
        new Date(selectedReportDate)
          .toLocaleDateString("pl-PL")
          .replaceAll("/", ".")
      );
      setResults(reportDate);
    } catch (e) {
      toast(e instanceof Error ? e.message : "Something went wrong", {
        type: "error",
        position: "bottom-left",
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentDatesChange = (event: any) => {
    const value = new Date(event.target.value);
    setSelectedReportDate(value.toISOString().split("T")[0]);
  };

  if (loading) {
    return (
      <>
        <Loader />
      </>
    );
  }

  return (
    <>
      <Row>
        <Form.Control
          type="date"
          value={selectedReportDate}
          onChange={handlePaymentDatesChange}
        />
        <BsButton onClick={getReport}>Get report</BsButton>
      </Row>
      {results ? (
        <Row>
          {Object.entries(results).map((x) => {
            const [paramName, result] = x;
            return (
              <>
                <Row>
                  <Col>
                    <>
                      <span>{paramName}:</span> <span>{Number(result)}</span>
                    </>
                  </Col>
                </Row>
              </>
            );
          })}
        </Row>
      ) : (
        <></>
      )}
    </>
  );
};
