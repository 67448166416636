import { useSelector } from "react-redux";
import { selectUser } from "../redux/selectors/userSelector";
import { useAuth0 } from "@auth0/auth0-react";
import { logoutUrl } from "../constants/apiEndpoints";

export function useUser() {
  const user = useSelector(selectUser);
  const { isAuthenticated, isLoading, logout, getAccessTokenSilently } =
    useAuth0();

  return {
    user,
    isAuthenticated,
    isLoading,
    logout: () => {
      logout({
        logoutParams: {
          returnTo: logoutUrl,
        },
      });
    },
    getAccessTokenSilently,
  };
}
