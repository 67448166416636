import { useEffect, useState } from "react";
import { Col, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { selectInformationModal } from "../../../redux/selectors/appSelector";
import { hideInformationModal } from "../../../redux/slices/appSlice"; 

export const InformationPopup = () => {
  const [show, setShow] = useState(false);
  const modal = useSelector(selectInformationModal);
  const dispatch = useDispatch();

  useEffect(() => {
    setShow(modal.isShown);
  }, [modal.isShown])

  const handleClose = () => dispatch(hideInformationModal());

  return (
    <Modal
      show={show}
      onHide={handleClose}
      keyboard={false}>
      <Modal.Body className={`align-items-center d-flex flex-column justify-content-center`}>
        <div className={`align-items-center d-flex flex-column justify-content-center`}>
          <Col xs={6} className="text-center pt-3 pb-3">
              <h4>{modal.header}</h4>
            <span>{modal.text}</span>
          </Col>
        </div>
      </Modal.Body>
    </Modal>
  )
}