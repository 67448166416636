const SLS_API_URI =
  process.env.REACT_APP_SLS_BACKEND_URL || "https://ld5lcri7mh.execute-api.eu-west-1.amazonaws.com/dev/" || "http://localhost:3005/dev/";

export const logoutUrl =
  process.env.REACT_APP_AUTH0_CALLBACK_LOGOUTURL || window.location.origin;

export const SLS_API_URL = `${SLS_API_URI}`;
export const SLS_USER_ENDPOINTS = {
  GET_USER_DISCORD_USERNAME: (id: string | number) => `user/${id}/discord`,
  PATCH_USER_DISCORD_USERNAME: (id: string | number) => `user/${id}/discord`,
  GET_USERS: (lastId?: string, email?: string, ethWallet?: string) =>
    `users?lastId=${lastId ?? ""}&email=${encodeURIComponent(
      email ?? ""
    )}&ethWallet=${ethWallet ?? ""}`,
  GET_USER: (id: string | number) => `user/${id}`,
  RECALCULATE_PATHS_AND_WORLDS: (id: string | number) =>
    `user/${id}/recalculate`,
  POST_USER: "user",
  PUT_USER: (id: string) => `user/${id}`,
  GET_HONEYPOT_USERS: "get-honeypot-users",
  GET_BADGES: "user/badges",
  GET_QUESTS: "user/quests",
  GET_REWARDS: "user/rewards",
  GET_EDU_BALANCE: `user/edu-balance`,
  GET_USER_HERO: (id: string) => `user-hero/${id}`,
  MODIFY_ACTIVE_PATH: (id: string) => `user/${id}/active-path`,
  MODIFY_WORLD: (id: string) => `user/${id}/world`,
  MODIFY_EXP: (id: string) => `user/${id}/exp`,
  FORCE_REFRESH_DISCORD_ROLES: (id: string) =>
    `user/force-refresh-discord-roles/${id}`,
  REMOVE_SKILLS: (id: string) => `user/${id}/remove-skill`,
  GET_AVAILABLE_WORLDS: (id: string) => `user/${id}/worlds/all-available`,
  GET_TASKS_FOR_PATH_LEVEL: (pathLevelId: string, userId: string) =>
    `tasks/${encodeURIComponent(pathLevelId)}?userId=${encodeURIComponent(
      userId
    )}`,
  GET_ANSWER_REQUEST: `answer/answerRequest`,
  POST_ADD_TASK: (userId: string) => `user/${userId}/task`,
  GET_RESEND_EMAIL: (userId: string, taskHeid: string) => `user/${userId}/task/${encodeURIComponent(taskHeid)}/resend`,
  DELETE_REMOVE_TASK: (userId: string, taskHeid: string) => `user/${userId}/task/${encodeURIComponent(taskHeid)}`,
  POST_EDU_RECORD: (userId: string) => `user/${userId}/edu-record`,
  GET_GENESIS_FOR_USER: (userId: string) => `user/${userId}/genesis`,
};
export const SLS_REPORTS_ENDPOINTS = {
  GET_STUDENTS_REPORT: (date: string) => `reports/students?reportDate=${date}`,
  GET_REFLINKS_REPORT: "reports/reflinks",
};

export const USERS = {
  PATCHME: "users/me",
};
