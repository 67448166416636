import { Col, Row } from "react-bootstrap";
import LogInSection from "./LogInSection";
import Logo from "./Logo";

import styles from "./Navbar.module.scss";

export const Navbar: any = () => {
  return (
    <Row
      className={`border-bottom pb-3 pt-3 justify-content-center ${styles.navBarContainer}`}
    >
      <div className={`${styles.navBar} d-flex`}>
        <Col xs={{ span: 5 }} sm={{ span: 3 }} md={{ span: 2 }}>
          <Logo />
        </Col>
        <Col className="d-none d-lg-block" lg={{ span: 8 }}></Col>
        <Col
          className="pb-2 align-self-center pt-1"
          xs={{ span: 7 }}
          sm={{ span: 5, offset: 4 }}
          md={{ span: 4, offset: 6 }}
          lg={{ span: 2, offset: 0 }}
        >
          <LogInSection />
        </Col>
      </div>
    </Row>
  );
};
