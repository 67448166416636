import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AppState {
  isFullscreenLoader: boolean,
  informationModal: {
    isShown: boolean,
    header: string | null,
    text: string | null,
  }
}

const initialState : AppState = {
  isFullscreenLoader: true,
  informationModal: {
    isShown: false,
    header: null,
    text: null,
  }
};

export const appSlice = createSlice({
  name: 'user',
  initialState:initialState,
  reducers: {
    showFullscreenLoader: (state) => {
      state.isFullscreenLoader = true;
    },
    hideFullscreenLoader: (state) => {
      state.isFullscreenLoader = false;
    },
    showInformationModal: (state, action: PayloadAction<{text: string, header: string}>) => {
      state.informationModal.isShown = true;
      state.informationModal.header = action.payload.header;
      state.informationModal.text = action.payload.text;
    },
    hideInformationModal: (state) => {
      state.informationModal.header = null;
      state.informationModal.text = null;
      state.informationModal.isShown = false;
    }
  },
})

export const {
  showFullscreenLoader,
  hideFullscreenLoader,
  showInformationModal,
  hideInformationModal } = appSlice.actions

export default appSlice.reducer