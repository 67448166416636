import { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import {
  ROUTE_ADMIN_PANEL_ADD_USER,
  ROUTE_ADMIN_PANEL_HONEYPOT_USERS,
  ROUTE_ADMIN_PANEL_REPORTS,
  ROUTE_ADMIN_PANEL_USER_WITH_WALLET,
} from "../../constants/routes";
import useSls from "../../hooks/useSls";
import Alert from "../Alert";
import BsButton from "../Common/Button";
import Loader from "../Loader";

export const AdminPanel = () => {
  const userCountLimit = 50;
  const [users, setUsers] =
    useState<{ id: string; ethWallet: string; email: string }[]>();
  const [isLoading, setIsLoading] = useState(true);
  const { getUsers } = useSls();
  const [queryParams] = useSearchParams();
  const isAlertSuccess = queryParams.get("userAddSuccess") === "true" ?? false;
  const [pageStatus, setPageStatus] =
    useState<{ pageNumber: number; lastKeyId: string }[]>();
  const [isPrevPageDisabled, setIsPrevPageDisabled] = useState(true);
  const [isNextPageDisabled, setIsNextPageDisabled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [emailFilter, setEmailFilter] = useState();
  const [ethWalletFilter, setEthWalletFilter] = useState();
  const [isResetFilterRequired, setIsResetFilterRequired] = useState(false);

  const handleEmailFilterChange = (event: any) => {
    const value = event.target.value;
    setEmailFilter(value);
  };

  const handleEthWalletChange = (event: any) => {
    const value = event.target.value;
    setEthWalletFilter(value);
  };

  useEffect(() => {
    getUsers({}).then((u) => {
      setUsers(u.items);
      setIsLoading(false);
      setPageStatus([
        { pageNumber: 1, lastKeyId: "" },
        { pageNumber: 2, lastKeyId: u.lastId },
      ]);
    });
  }, []);

  useEffect(() => {
    if (users && users?.length === userCountLimit) {
      setIsNextPageDisabled(false);
    } else {
      setIsNextPageDisabled(true);
    }
  }, [users]);

  useEffect(() => {
    if (currentPage === 1) {
      setIsPrevPageDisabled(true);
    } else {
      setIsPrevPageDisabled(false);
    }
  }, [currentPage]);

  const loadNextPage = async () => {
    debugger;
    setIsLoading(true);
    let activePage = currentPage + 1;

    setCurrentPage(activePage);
    if (pageStatus) {
      const currentPageStatus = pageStatus.find(
        (ps) => ps.pageNumber === activePage
      );
      const users = await getUsers({ lastId: currentPageStatus!.lastKeyId });
      setUsers(users.items);
      setPageStatus([
        ...pageStatus,
        { lastKeyId: users.lastId, pageNumber: activePage + 1 },
      ]);
    }
    setIsLoading(false);
  };

  const loadPrevPage = async () => {
    debugger;
    setIsLoading(true);
    let activePage = currentPage - 1;
    setCurrentPage(activePage);
    if (pageStatus) {
      const currentPageStatus = pageStatus.find(
        (ps) => ps.pageNumber === activePage
      );
      const users = await getUsers({ lastId: currentPageStatus!.lastKeyId });
      setUsers(users.items);
    }
    setIsLoading(false);
  };

  const searchByEmail = async () => {
    setIsLoading(true);
    const users = await getUsers({ email: emailFilter });
    setUsers(users.items);
    setIsResetFilterRequired(true);
    setIsLoading(false);
  };

  const searchByEthWallet = async () => {
    setIsLoading(true);
    const users = await getUsers({ ethWallet: ethWalletFilter });
    setUsers(users.items);
    setIsResetFilterRequired(true);
    setIsLoading(false);
  };

  const resetFilters = async () => {
    setIsLoading(true);
    const users = await getUsers({});
    setUsers(users.items);
    setIsResetFilterRequired(false);
    setEthWalletFilter(undefined);
    setEmailFilter(undefined);
    setIsLoading(false);
  };

  return (
    <>
      <Alert
        disissable
        variant="success"
        header="Gu dźob!"
        content="Udało się dodać usera"
        show={isAlertSuccess}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Row className="pb-3 pt-3">
            <Col xs={2}>
              <Link to={ROUTE_ADMIN_PANEL_ADD_USER}>
                <BsButton>
                  <i className="bi bi-person-plus-fill"></i>
                </BsButton>
              </Link>
            </Col>
            <Col xs={2}>
              <Link to={ROUTE_ADMIN_PANEL_HONEYPOT_USERS}>
                <BsButton>Honeypot users</BsButton>
              </Link>
            </Col>
            <Col xs={2}>
              <Link to={ROUTE_ADMIN_PANEL_REPORTS}>
                <BsButton>Reports</BsButton>
              </Link>
            </Col>
          </Row>
          <Row className="pb-3 pt-3">
            <Col xs={4}>
              <input
                disabled={emailFilter}
                type="text"
                placeholder="ethWallet"
                value={ethWalletFilter}
                onChange={handleEthWalletChange}
              />
              <BsButton disabled={emailFilter} onClick={searchByEthWallet}>
                Search by ethWallet
              </BsButton>
            </Col>
            <Col xs={4}>
              <input
                disabled={ethWalletFilter}
                type="text"
                placeholder="email"
                value={emailFilter}
                onChange={handleEmailFilterChange}
              />
              <BsButton disabled={ethWalletFilter} onClick={searchByEmail}>
                Search by mail
              </BsButton>
            </Col>
            <Col xs={4}>
              <BsButton
                disabled={!isResetFilterRequired}
                onClick={resetFilters}
              >
                Reset
              </BsButton>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>EhtWalletAddress</th>
                    <th>Email</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {users &&
                    users.map((u, i) => (
                      <tr key={i}>
                        <td>{(currentPage - 1) * userCountLimit + (i + 1)}</td>
                        <td>{u.ethWallet}</td>
                        <td>{u.email}</td>
                        <td>
                          <Link to={ROUTE_ADMIN_PANEL_USER_WITH_WALLET(u.id)}>
                            <BsButton>Show</BsButton>
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row className="justify-content-around pb-4">
            <Col xs={2}>
              <BsButton disabled={isPrevPageDisabled} onClick={loadPrevPage}>
                Prev page
              </BsButton>
            </Col>
            <Col xs={2}>
              <BsButton disabled={isNextPageDisabled} onClick={loadNextPage}>
                Next page
              </BsButton>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
