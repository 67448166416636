import React, {useState} from "react";
import {Modal, DatePicker} from 'antd';
import type { DatePickerProps } from 'antd';
import Button from "../../Common/Button";
import dayjs from "dayjs";
import {Form} from "react-bootstrap";
import useSls from "../../../hooks/useSls";
import User from "../../../types/sls/User";
import { toast } from "react-toastify";


export const AddEduRecord = ({userId, setLoader, setUser}: {userId: string,
  setLoader: (isLoading:boolean) => void
  setUser: (user: User) => void
}) => {
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [date, setDate] = useState<string>((new Date()).toISOString());
  const [amount, setAmount] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { addEduRecord } = useSls();

  const handleOk = async () => {
    if(!title){
      alert("Title is required");
      return;
    }
    if(!amount){
      alert("Amount is required");
      return;
    }
    if(!date){
      alert("Date is required");
      return;
    }
    setLoader(true);
    const user = await addEduRecord(userId, {title,
      description, amount: amount.toString(), when: new Date(date)});
    setUser(user);
    setIsModalOpen(false);
    setLoader(false);
    toast("Edu modified", {
      type: "info",
      position: "top-center",
      theme: "colored",
    });
  };

  const handleCancel = () => {
    setTitle("");
    setDescription("");
    setDate((new Date()).toISOString());
    setAmount(0);
    setIsModalOpen(false);
  };

  const datePickerOnChange: DatePickerProps['onChange'] = (date, dateString) => {
    setDate(dateString);
  };

  return <>
    <Modal title={"Add EDU"} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <Form.Group
        className="mb-3"
      >
        <Form.Label>Title</Form.Label>
        <Form.Control
          value={title}
          onChange={e => setTitle(e.target.value)}
          type={"text"}
          required={true}
        />
        <Form.Label>Description</Form.Label>
        <Form.Control
          value={description}
          onChange={e => setDescription(e.target.value)}
          type={"text"}
        />
        <Form.Label>Amount</Form.Label>
        <Form.Control
          value={amount}
          onChange={e => setAmount(e.target.value as unknown as number)}
          type={"number"}
          required={true}
        />
        <Form.Label>Date</Form.Label>
        <br/>
        <DatePicker defaultValue={dayjs(date)} onChange={datePickerOnChange} />
      </Form.Group>
    </Modal>
    <Button onClick={() => setIsModalOpen(true)}>Add edu</Button>
  </>
}
