import * as React from 'react';
import {Button, ButtonProps, Spinner} from "react-bootstrap";
import {toast} from "react-toastify";
import BsButton from "../../Common/Button";

type PropsT = {
    children: React.ReactNode
    action: () => Promise<void>
    useBsButton?: boolean
} & ButtonProps

export const ActionButton = ({ children, action, useBsButton = false, ...props }: PropsT) => {
    const [isLoading, setLoading] = React.useState(false)

    const handleOnClick = async () => {
        setLoading(true);

        try {
            await action();
        } catch (e) {
            toast(e instanceof Error ? e.message : 'Something went wrong', {
                type: "error",
                position: 'bottom-left',
                theme: 'colored'
            });
        } finally {
            setLoading(false)
        }
    }

    const Component = useBsButton ? BsButton : Button

    // @ts-ignore
    return (
        <Component {...props} onClick={handleOnClick} disabled={isLoading}>
            { isLoading ? (
                <Spinner animation="border" role="status" size={'sm'}>
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            ) : children}
        </Component>
    );
};
