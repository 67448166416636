import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import style from "./style.module.scss";
import { ROUTE_HOME } from "../../../../constants/routes";
import { logOut } from "../../../../redux/slices/userSlice";
import { AppDispatch } from "../../../../redux/store";
import { useUser } from "../../../../hooks/useUser";

const LoggedIn = () => {
  const { user, logout } = useUser();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const signOut = async () => {
    logout();
    await dispatch(logOut());
    navigate(ROUTE_HOME);
  };

  return (
    <div className="d-flex justify-content-end justify-content-sm-between flex-column align-items-end">
      <div className={`${style.account} opacity-50d-none d-sm-block`}>
        {user?.email}
      </div>
      <div className={`float-end fs-6`}>
        <a onClick={() => signOut()} className={style.disconnect}>
          <i className="bi bi-wallet-fill pe-2"></i>
          <span className="opacity-75">Log out</span>
        </a>
      </div>
    </div>
  );
};

export default LoggedIn;
