import { useEffect, useState } from "react";
import useSls from "../../../../hooks/useSls";
import { toast } from "react-toastify";
import Loader from "../../../Loader";
import { Table } from "react-bootstrap";
import { Referral } from "../../../../types/Referral";
import { validate as isValidUUID } from "uuid";

export const Reflinks = () => {
  const { getReflinksReport } = useSls();
  const [results, setResults] = useState<Referral[] | null>(null);

  useEffect(() => {
    getReflinksReport()
      .then((x) => {
        setResults(x);
      })
      .catch((e) => {
        toast(e instanceof Error ? e.message : "Something went wrong", {
          type: "error",
          position: "bottom-left",
          theme: "colored",
        });
      });
  }, []);

  if (!results) {
    return (
      <>
        <Loader />
      </>
    );
  }

  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Refcode</th>
            <th>Who is referred</th>
          </tr>
        </thead>
        <tbody>
          {results.map((x) => {
            const refCodeUrl = `/admin/user/${x.referralCode}`;
            const userIdUrl = `/admin/user/${x.userId}`;

            return (
              <tr>
                <td>
                  {isValidUUID(x.referralCode) ? (
                    <a href={refCodeUrl}>{x.referralCode}</a>
                  ) : (
                    x.referralCode
                  )}
                </td>
                <td>
                  {isValidUUID(x.userId) ? (
                    <a href={userIdUrl}>{x.userId}</a>
                  ) : (
                    x.userId
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};
