import BsButton from "../../Common/Button";
import {useState} from "react";
import useSls from "../../../hooks/useSls";
import {sum} from "lodash";
import Loader from "../../Loader";

export const GenesisCounter = ({userId}: { userId: string }) => {
  const [gold, setGold] = useState<number | string>("?");
  const [normal, setNormal] = useState<number | string>("?");
  const [loading, setLoading] = useState<boolean>(false);
  const {getGenesisForUser} = useSls();
  const getGenesisForUserHandler = async () => {
    setLoading(true);
    const genesis = await getGenesisForUser(userId);
    if (!genesis) {
      setGold(0);
      setNormal(0);
    } else {
      const gold = sum(genesis.map(x => x.type === "gold" ? 1 : 0));
      setGold(gold);
      const normal = sum(genesis.map(x => x.type === "normal" ? 1 : 0));
      setNormal(normal);
    }
    setLoading(false)
  }

  if (loading) return <BsButton disabled><Loader height={"20px"}/></BsButton>;

  return <BsButton onClick={getGenesisForUserHandler}>Genesis - Gold: {gold}, Normal: {normal}</BsButton>;
}

export default GenesisCounter;