import { Navigate } from "react-router-dom";
import { ROUTE_HOME } from "../../../constants/routes";
import { useUser } from "../../../hooks/useUser";
import Loader from "../../Loader";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { user, isAuthenticated, isLoading } = useUser();

  if (isLoading) {
    return <Loader height="100vh" />;
  }

  if (isAuthenticated && user) {
    return children;
  }

  return <Navigate to={ROUTE_HOME} replace />;
};

export default ProtectedRoute;
