import {TreeSelect} from "antd";
import type {TreeSelectProps} from 'antd';
import type {DefaultOptionType} from 'antd/es/select';
import {Button, Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import useSls from "../../../hooks/useSls";
import {toast} from "react-toastify";
import User from "../../../types/sls/User";

export const AddTaskToInProgress = ({onTaskAdded, beforeTaskAdded, user, onTaskAddFailed}:
                                      {
                                        onTaskAdded: () => Promise<void>,
                                        beforeTaskAdded: () => void,
                                        user: User,
                                        onTaskAddFailed: () => void,
                                      }) => {
  const {getObjectChildren, addTask} = useSls();
  const [value, setValue] = useState<string>();
  const [treeData, setTreeData] = useState<Omit<DefaultOptionType, 'label'>[]>([]);

  useEffect(() => {
    setTreeData([...user.gameData.worlds.map(x => ({
      id: x.worldName,
      pId: 0,
      value: x.worldName,
      title: x.displayName,
      selectable: false,
    }))]);
  }, [])

  const onLoadData: TreeSelectProps['loadData'] = async ({id}) => {
    const data = await getObjectChildren(id);
    if (!data) return;
    setTreeData([...treeData, ...data]);
  }

  const onChange = (newValue: string) => {
    console.log(newValue);
    setValue(newValue);
  };

  const addTaskHandler = async () => {
    beforeTaskAdded();
    const added = await addTask(user.id, value!);
    if(added.success){
      await onTaskAdded();
      return;
    }
    toast(`Zadanie ${value} nie dodało się 🥲! ${added.message}`, {type: 'error'});
    onTaskAddFailed();
  }

  return (
    <>
      <Form.Group
        className="mb-3"
        controlId={`formBasicTaskInProgressAdd`}
      >
        <Form.Label>Add task in progress</Form.Label>
        <TreeSelect
          style={{width: '100%'}}
          dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
          value={value}
          treeDataSimpleMode
          treeData={treeData}
          loadData={onLoadData}
          onChange={onChange}
        />
        <Button disabled={!value} variant='outline-primary' onClick={addTaskHandler}>Add</Button>
      </Form.Group>
    </>
  )
}