import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Layout from "../Layout";
import {
  ROUTE_ADMIN_PANEL,
  ROUTE_ADMIN_PANEL_ADD_USER,
  ROUTE_ADMIN_PANEL_HONEYPOT_USERS,
  ROUTE_ADMIN_PANEL_REPORTS,
  ROUTE_ADMIN_PANEL_REPORT_REFLINKS,
  ROUTE_ADMIN_PANEL_REPORT_STUDENTS,
  ROUTE_ADMIN_PANEL_USER,
  ROUTE_HOME,
  ROUTE_LOGIN_SUCCESS,
} from "../../constants/routes";
import Home from "../Home";
import ProtectedRoute from "../Auth/ProtectedRoute";
import { PreventIfLoggedIn } from "../Auth/PreventIfLoggedIn";
import { InformationPopup } from "../Popups/InformationPopup/InformationPopup";
import AdminPanel from "../AdminPanel";
import AdminProtected from "../Auth/AdminProtected";
import SingleUser from "../AdminPanel/SingleUser";
import AddUser from "../AdminPanel/AddUser";
import ListHoneypotUsers from "../AdminPanel/ListHoneypotUsers";
import "react-toastify/dist/ReactToastify.css";
import { Reports } from "../AdminPanel/Reports/Reports";
import { Students } from "../AdminPanel/Reports/Students/Students";
import { Reflinks } from "../AdminPanel/Reports/Reflinks/Reflinks";
import { LoginSuccess } from "../LoginSuccess";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { loginUser } from "../../redux/slices/userSlice";
import { AppDispatch } from "../../redux/store";
import {
  hideFullscreenLoader,
  showFullscreenLoader,
} from "../../redux/slices/appSlice";
import { useUser } from "../../hooks/useUser";

export const App = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useUser();

  useEffect(() => {
    const f = async () => {
      dispatch(showFullscreenLoader());
      return dispatch(loginUser({ getAccessTokenSilently }));
    };

    if (!isAuthenticated && isLoading) {
      return;
    }

    f()
      .then(() => {
        dispatch(hideFullscreenLoader());
      })
      .catch((e) => {
        console.error(e);
      });
  }, [isAuthenticated, isLoading]);

  return (
    <Layout showNavbar>
      <Routes>
        <Route
          path={ROUTE_HOME}
          element={
            <PreventIfLoggedIn>
              <Home />
            </PreventIfLoggedIn>
          }
        />
        <Route path={ROUTE_LOGIN_SUCCESS} element={<LoginSuccess />} />
        <Route
          path={ROUTE_ADMIN_PANEL}
          element={
            <ProtectedRoute>
              <AdminProtected redirect>
                <AdminPanel />
              </AdminProtected>
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTE_ADMIN_PANEL_USER}
          element={
            <ProtectedRoute>
              <AdminProtected redirect>
                <SingleUser />
              </AdminProtected>
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTE_ADMIN_PANEL_ADD_USER}
          element={
            <ProtectedRoute>
              <AdminProtected redirect>
                <AddUser />
              </AdminProtected>
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTE_ADMIN_PANEL_HONEYPOT_USERS}
          element={
            <ProtectedRoute>
              <AdminProtected redirect>
                <ListHoneypotUsers />
              </AdminProtected>
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTE_ADMIN_PANEL_REPORTS}
          element={
            <ProtectedRoute>
              <AdminProtected redirect>
                <Reports />
              </AdminProtected>
            </ProtectedRoute>
          }
        >
          <Route
            path={ROUTE_ADMIN_PANEL_REPORT_STUDENTS}
            element={
              <ProtectedRoute>
                <AdminProtected redirect>
                  <Students />
                </AdminProtected>
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTE_ADMIN_PANEL_REPORT_REFLINKS}
            element={
              <ProtectedRoute>
                <AdminProtected redirect>
                  <Reflinks />
                </AdminProtected>
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
      <InformationPopup />
      <ToastContainer />
    </Layout>
  );
};
