import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectShowFullscreenLoader } from "../../redux/selectors/appSelector";
import Loader from "../Loader";
import Navbar from "./Navbar";
import styles from "./styles.module.scss";

interface LayoutProps {
  showNavbar: boolean;
  children: React.ReactNode;
}

export const Layout = ({ children, showNavbar, ...props }: LayoutProps) => {
  const showLoader = useSelector(selectShowFullscreenLoader);
  return (
    <div className={styles.layout}>
      {showLoader ? (
        <Loader height="100vh" />
      ) : (
        <Container fluid>
          {showNavbar && <Navbar {...props} />}
          <div className={styles.wrapper}>{children}</div>
        </Container>
      )}
    </div>
  );
};
