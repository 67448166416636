import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate();

  const domain = process.env.REACT_APP_AUTH0_DOMAIN || "heyedu.eu.auth0.com";
  const clientId =
    process.env.REACT_APP_AUTH0_CLIENT_ID || "7KKFO23HNwFvy47kqfdBzblYcu9sO4OM";
  const redirectUri =
    process.env.REACT_APP_AUTH0_CALLBACK_URL ||
    window.location.origin + "/login-success";

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirectUri,
        audience: "heyedu-sls",
      }}
      onRedirectCallback={async (a, u) => {
        if (u) {
          navigate(a?.returnTo || window.location.pathname);
        }
      }}
    >
      {children}
    </Auth0Provider>
  );
};
