import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  Button,
  Col,
  Form,
  FormGroup,
  InputGroup,
  OverlayTrigger,
  Popover,
  Row,
  Tooltip,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { ROUTE_ADMIN_PANEL } from "../../../constants/routes";
import useSls from "../../../hooks/useSls";
import User from "../../../types/sls/User";
import BsButton from "../../Common/Button";
import Loader from "../../Loader";
import { ActionButton } from "../../Common/ActionButton";
import { toast } from "react-toastify";

import styles from "./SingleUser.module.scss";
import axios, { AxiosError } from "axios";
import { errorCodeMap } from "../../../constants/errorCodes";
import StudentActivityStatus from "../../../constants/studentActivityStatus";
import ModifyPathPopover from "./ModifyPathPopover";
import AddPathPopover from "./AddPathPopover";
import { TreeSelect, List } from "antd";
import { AddTaskToInProgress } from "./AddTaskToInProgress";
import { AddEduRecord } from "./AddEduRecord";
import GenesisCounter from "./GenesisCounter";

export const SingleUser = () => {
  const params = useParams();
  const id = params.id;
  if (!id) {
    throw new Error("Id param missing!");
  }

  const [user, setUser] = useState<User | undefined>();
  const {
    updateUser,
    getUser,
    getUserDiscord,
    modifyWorld,
    recalculatePathsAndWorlds,
    forceRefreshDiscordRoles,
    removeSkill: removeSkillRequest,
    modifyExp,
    getAvailableWorlds,
    resendTask,
    removeTask,
  } = useSls();

  const [isLoading, setIsLoading] = useState(true);
  const [kustomerIdEditable, setKustomerIdEditable] = useState(false);
  const [showAddPointsPopup, setShowAddPointsPopup] = useState(false);
  const [showAddWorldPopup, setShowAddWorldPopup] = useState(false);
  const [showRemoveSkillPopup, setShowRemoveSkillPopup] = useState<any>({});
  const addPointsPopupInputRef = useRef<HTMLInputElement>(null);
  const [doublePointExp, setDoublePointExp] = useState(false);
  const [isAddNewWorldDisabled, setIsAddNewWorldDisabled] = useState(true);
  const [allAvailableWorlds, setAllAvailableWorlds] = useState<
    {
      heid: string;
      displayName: string;
      testMode: boolean;
    }[]
  >([]);
  const finishedTasksSelectRef = useRef<HTMLSelectElement>(null);
  const tasksInProgressSelectRef = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    getUser({ id: id }).then((u) => {
      setUser(u);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (user?.gameData?.heroLevel === 6 || user?.gameData?.heroLevel === 15) {
      setDoublePointExp(true);
    } else {
      setDoublePointExp(false);
    }
  }, [user]);

  useEffect(() => {
    if (!allAvailableWorlds || allAvailableWorlds.length === 0) {
      setIsAddNewWorldDisabled(true);
    } else {
      setIsAddNewWorldDisabled(false);
    }
  }, [allAvailableWorlds]);

  useEffect(() => {
    if (user?.gameData?.skills?.length) {
      setShowRemoveSkillPopup([]);
      for (const s of user.gameData.skills) {
        setShowRemoveSkillPopup({
          ...showRemoveSkillPopup,
          [s.fullPathId]: false,
        });
      }
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      getAvailableWorlds(user.id).then((x) => {
        setAllAvailableWorlds(x);
      });
    }
  }, [user]);

  const handleChange = (event: any) => {
    const { value, pattern, classList } = event.target;
    let resultingValue = value;
    if (pattern) {
      if (!value.match(pattern) && value !== "") {
        classList.add("is-invalid");
        resultingValue = null;
      } else {
        classList.remove("is-invalid");
      }
    }
    //@ts-ignore
    setUser({
      ...user,
      [event.target.name]: resultingValue,
    });
  };

  const handleSubscribedChange = (event: any) => {
    const value = event.target.checked;
    //@ts-ignore
    setUser({
      ...user,
      //@ts-ignore
      payment: {
        ...user?.payment,
        subscribed: value,
      },
    });
  };

  const handleClaimChange = (event: any) => {
    const value = event.target.checked;
    const name = event.target.name;
    //@ts-ignore
    setUser({
      ...user,
      //@ts-ignore
      claims: {
        ...user?.claims,
        [name]: value,
      },
    });
  };

  const handlePaymentChange = (event: any) => {
    const value = event.target.value;
    //@ts-ignore
    setUser({
      ...user,
      //@ts-ignore
      payment: {
        ...user?.payment,
        [event.target.name]: value,
      },
    });
  };

  const handleSystemDataChange = (event: any) => {
    const value = event.target.value;
    //@ts-ignore
    setUser({
      ...user,
      //@ts-ignore
      systemData: {
        ...user?.systemData,
        [event.target.name]: value,
      },
    });
  };

  const handleMetadataChange = (event: any) => {
    const value = event.target.value;
    //@ts-ignore
    setUser({
      ...user,
      //@ts-ignore
      metadata: {
        ...user?.metadata,
        [event.target.name]: value,
      },
    });
  };

  const handlePrefferedHoursChange = (event: any) => {
    const value = event.target.value;

    //@ts-ignore
    setUser({
      ...user,
      //@ts-ignore
      metadata: {
        ...user?.metadata,
        prefferedEmailHours: value.split(","),
      },
    });
  };

  const handleGameDataChange = (event: any) => {
    const value = event.target.value;
    //@ts-ignore
    setUser({
      ...user,
      //@ts-ignore
      gameData: {
        ...user?.gameData,
        [event.target.name]: value,
      },
    });
  };

  const addPointsPopup = async () => {
    //@ts-ignore
    const points = Number(addPointsPopupInputRef?.current?.value);
    let sum: number;
    if (doublePointExp) {
      sum = (user?.gameData.exp ?? 0) + points * 2;
    } else {
      sum = (user?.gameData.exp ?? 0) + points;
    }

    try {
      setIsLoading(true);
      const userResponse = await modifyExp({ userId: user!.id, exp: sum });
      if (userResponse.gameData.heroLevel !== user?.gameData.heroLevel) {
        toast("Hey! Poziom klienta uległ zmianie!", {
          type: "info",
          position: "top-center",
          theme: "colored",
          progress: 1,
          hideProgressBar: true,
        });
      }
      setUser(userResponse);
      setShowAddPointsPopup(false);
    } catch (e) {
      toast(e instanceof Error ? e.message : "Something went wrong", {
        type: "error",
        position: "bottom-left",
        theme: "colored",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleMetadataSelectChange = (event: any) => {
    const options = event.target.options;
    const values = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        values.push(options[i].value);
      }
    }
    setUser({
      ...user,
      //@ts-ignore
      metadata: {
        ...user?.metadata,
        [event.target.name]: values,
      },
    });
  };

  const handlePaymentDatesChange = (event: any) => {
    const value = new Date(event.target.value);
    //@ts-ignore
    setUser({
      ...user,
      //@ts-ignore
      payment: {
        ...user?.payment,
        [event.target.name]: value.toISOString(),
      },
    });
  };

  const handleSystemDataGenderChange = (event: any) => {
    const options = event.target.options;
    const value = options[options.selectedIndex].value;
    setUser({
      ...user,
      //@ts-ignore
      systemData: {
        ...user?.systemData,
        [event.target.name]: value,
      },
    });
  };

  const handleStudentActivityStatusChange = (event: any) => {
    const options = event.target.options;
    const value = options[options.selectedIndex].value;

    //@ts-ignore
    setUser({
      ...user,
      studentActivityStatus: value,
    });
  };

  const handleCryptoWorldChange = (event: any) => {
    const value = event.target.value;
    //@ts-ignore
    setUser({
      ...user,
      //@ts-ignore
      worlds: {
        ...user?.worlds,
        //@ts-ignore
        cryptoWorld: {
          ...user?.worlds.cryptoWorld,
          [event.target.name]: value,
        },
      },
    });
  };

  const handleCopywritingWorldChange = (event: any) => {
    const value = event.target.value;
    //@ts-ignore
    setUser({
      ...user,
      //@ts-ignore
      worlds: {
        ...user?.worlds,
        //@ts-ignore
        copywriting: {
          ...user?.worlds.copywriting,
          [event.target.name]: value,
        },
      },
    });
  };

  const handleProductivityWorldChange = (event: any) => {
    const value = event.target.value;
    //@ts-ignore
    setUser({
      ...user,
      //@ts-ignore
      worlds: {
        ...user?.worlds,
        //@ts-ignore
        productivity: {
          ...user?.worlds.productivity,
          [event.target.name]: value,
        },
      },
    });
  };

  const handleAiHackersWorldChange = (event: any) => {
    const value = event.target.value;
    //@ts-ignore
    setUser({
      ...user,
      //@ts-ignore
      worlds: {
        ...user?.worlds,
        //@ts-ignore
        aiHackers: {
          ...user?.worlds.productivity,
          [event.target.name]: value,
        },
      },
    });
  };

  const handleSideHustleWorldChange = (event: any) => {
    const value = event.target.value;
    //@ts-ignore
    setUser({
      ...user,
      //@ts-ignore
      worlds: {
        ...user?.worlds,
        //@ts-ignore
        sideHustleWorld: {
          ...user?.worlds.sideHustleWorld,
          [event.target.name]: value,
        },
      },
    });
  };

  const handlePersonalFinanceWorldChange = (event: any) => {
    const value = event.target.value;
    //@ts-ignore
    setUser({
      ...user,
      //@ts-ignore
      worlds: {
        ...user?.worlds,
        //@ts-ignore
        personalFinanceWorld: {
          ...user?.worlds.personalFinanceWorld,
          [event.target.name]: value,
        },
      },
    });
  };

  const handleTechnicalPersonalBrandWorldChange = (event: any) => {
    const value = event.target.value;
    //@ts-ignore
    setUser({
      ...user,
      //@ts-ignore
      worlds: {
        ...user?.worlds,
        //@ts-ignore
        technicalPersonalBrand: {
          ...user?.worlds.technicalPersonalBrand,
          [event.target.name]: value,
        },
      },
    });
  };

  const handleGoalsChange = (event: any) => {
    const value = event.target.value.split("\n");
    //@ts-ignore
    setUser({
      ...user,
      //@ts-ignore
      systemData: {
        ...user?.systemData,
        goals: value,
      },
    });
  };

  const handleGallupChange = (event: any) => {
    const value = event.target.value.split("\n");
    //@ts-ignore
    setUser({
      ...user,
      //@ts-ignore
      metadata: {
        ...user?.metadata,
        gallup: value,
      },
    });
  };

  const saveChanges = async () => {
    setIsLoading(true);
    try {
      const u = await updateUser({ id: user!.id, user: user! });
      setUser(u);
    } catch (e) {
      toast(e instanceof Error ? e.message : "Something went wrong", {
        type: "error",
        position: "bottom-left",
        theme: "colored",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const loadDiscordUsername = async () => {
    try {
      const { discordUsername } = await getUserDiscord({ id });

      //@ts-ignore
      setUser({
        ...user,
        discordUsername,
      });
    } catch (e) {
      console.log(e);
      throw new Error(
        e instanceof Error
          ? `Cannot fetch discord username: ${e.message}`
          : "Cannot fetch discord username"
      );
    }
  };

  const disconnectDiscord = async () => {
    //@ts-ignore
    setUser({
      ...user,
      discordUsername: null,
      discordId: null,
    });
  };

  const addPointsPopover = () => {
    const keyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.code === "Enter") {
        addPointsPopup();
      }
    };

    return (
      <Popover id="addpoints-popover">
        <Popover.Header as="h3">Add points</Popover.Header>
        <Popover.Body>
          <div>
            Insert how many points you want to add{" "}
            {doublePointExp ? (
              <sup className="fst-italic" style={{ color: "red" }}>
                points will be doubled because of lvl perks.
              </sup>
            ) : (
              <></>
            )}
          </div>
          <div>
            <input
              type="number"
              ref={addPointsPopupInputRef}
              onKeyDown={keyDownHandler}
            />
          </div>
          <div className="pt-2">
            <Button onClick={addPointsPopup}>Save</Button>
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  const addWorldsPopover = () => {
    const addWorld = async (heid: string) => {
      setIsLoading(true);
      const result = await modifyWorld({
        userId: user!.id,
        worldName: heid,
        action: "addWorld",
      });
      setUser(result);
      setShowAddWorldPopup(false);
      setIsLoading(false);
    };

    return (
      <Popover id="addWorld-popover" style={{ width: "200px" }}>
        <Popover.Header as="h3">Add world</Popover.Header>
        <Popover.Body>
          <>
            {allAvailableWorlds.map((aw, i) => (
              <div key={i} className="mb-1">
                <div className="align-items-center d-flex justify-content-between">
                  <span>{aw.displayName}</span>
                  {aw.testMode ? (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip">
                          !!World under test!!
                        </Tooltip>
                      }
                    >
                      <span>
                        <Button
                          variant="warning"
                          onClick={() => addWorld(aw.heid)}
                        >
                          Add
                        </Button>
                      </span>
                    </OverlayTrigger>
                  ) : (
                    <span>
                      <Button onClick={() => addWorld(aw.heid)}>Add</Button>
                    </span>
                  )}
                </div>
              </div>
            ))}
          </>
        </Popover.Body>
      </Popover>
    );
  };

  interface removeSkillPopoverInputModel {
    fullPathId: string;
    displayName: string;
  }

  const modifyRemoveSkillsPopover = ({
    fullPathId,
    displayName,
  }: removeSkillPopoverInputModel) => {
    const removeSkill = async (fullPathId: string) => {
      setIsLoading(true);
      setShowRemoveSkillPopup(!showRemoveSkillPopup[fullPathId]);
      try {
        const result = await removeSkillRequest({
          fullPathId,
          userId: user!.id,
        });
        setUser(result);
      } catch (err) {
        const error = err as Error | AxiosError<{ errorCode: string }>;
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 418) {
            //@ts-ignore
            const errorCode = error.response.data!.errorCode as string;
            const message = errorCodeMap[errorCode];
            toast(message, {
              type: "error",
              position: "bottom-left",
              theme: "colored",
              progress: 1,
              hideProgressBar: true,
            });
          }
        }
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <Popover style={{ width: "400px" }} id={`modify-popover-${displayName}`}>
        <Popover.Header as="h3">Remove skill {displayName}??</Popover.Header>
        <Popover.Body>
          <div className="d-flex justify-content-evenly">
            <div>
              <Button onClick={() => removeSkill(fullPathId)} variant="success">
                Remove
              </Button>
            </div>
          </div>
        </Popover.Body>
      </Popover>
    );
  };

  const removeWorld = async (heid: string) => {
    setIsLoading(true);
    const result = await modifyWorld({
      userId: user!.id,
      worldName: heid,
      action: "removeWorld",
    });
    setUser(result);
    setShowAddWorldPopup(false);
    setIsLoading(false);
  };

  const recalulateAutogeneratedData = async () => {
    setIsLoading(true);
    const result = await recalculatePathsAndWorlds({ userId: user!.id });
    setUser(result);
    setIsLoading(false);
  };

  const resendTaskHandler = async (ref: React.RefObject<any>) => {
    const taskHeid = ref?.current?.value;
    if (!taskHeid) {
      return;
    }
    setIsLoading(true);
    await resendTask(user!.id, taskHeid);
    const refetchedUser = await getUser({ id: user!.id });
    setUser(refetchedUser);
    setIsLoading(false);
    toast(`Zadanie ${taskHeid} ponownie wysłane na maila`, {
      type: "info",
      position: "top-center",
      theme: "colored",
      hideProgressBar: false,
      autoClose: 3000,
    });
  };

  const removeTaskHandler = async (ref: React.RefObject<any>) => {
    const taskHeid = ref?.current?.value;
    if (!taskHeid) {
      return;
    }
    setIsLoading(true);
    const resultingUser = await removeTask(user!.id, taskHeid);
    setUser(resultingUser);
    setIsLoading(false);
    toast(`Zadanie ${taskHeid} usunięte z aktywnych zadań`, {
      type: "info",
      position: "top-center",
      theme: "colored",
      hideProgressBar: false,
      autoClose: 3000,
    });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Row>
            <Col xs={3} className={`pt-3 pb-3`}>
              <Link to={ROUTE_ADMIN_PANEL}>
                <BsButton>Go back</BsButton>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form>
                <Accordion
                  defaultActiveKey={["systemData", "gameData"]}
                  alwaysOpen
                >
                  <Accordion.Item eventKey="claims">
                    <Accordion.Header>Claims</Accordion.Header>
                    <Accordion.Body>
                      <Row>
                        <Col xs={2}>
                          <Form.Group
                            className="mb-3"
                            controlId={`formBasicIsAdmin`}
                          >
                            <Form.Label>Is admin</Form.Label>
                            <Form.Check
                              type="switch"
                              checked={!!user?.claims.isAdmin}
                              disabled
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={2}>
                          <Form.Group
                            className="mb-3"
                            controlId={`formBasicCanCheckTasks`}
                          >
                            <Form.Label>Can check tasks</Form.Label>
                            <Form.Check
                              type="switch"
                              checked={!!user?.claims.canCheckTasks}
                              onChange={handleClaimChange}
                              name={"canCheckTasks"}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="systemDetails">
                    <Accordion.Header>System details</Accordion.Header>
                    <Accordion.Body>
                      <Row>
                        <Col xs={4}>
                          <Form.Group
                            className="mb-3"
                            controlId={`formBasicId`}
                          >
                            <Form.Label>Id</Form.Label>
                            <Form.Control
                              disabled
                              type="text"
                              placeholder="Id"
                              value={user?.id}
                              name="id"
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group
                            className="mb-3"
                            controlId={`formBasicAvatarUrl`}
                          >
                            <Form.Label>Avatar url</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder={"Avatar url"}
                              value={user?.systemData?.avatarUrl}
                              onChange={handleSystemDataChange}
                              name="avatarUrl"
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={2}>
                          <Button
                            variant="outline-info"
                            onClick={() =>
                              forceRefreshDiscordRoles({ userId: user!.id })
                            }
                          >
                            Refresh roles
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <Form.Group
                            className="mb-3"
                            controlId={`formBasicEthWallet`}
                          >
                            <Form.Label>Wallet ETH</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder={"Wallet ETH"}
                              value={user?.ethWallet}
                              onChange={handleChange}
                              name="ethWallet"
                              pattern={"^0x[a-fA-F0-9]{40}$"}
                            />
                            <Form.Control.Feedback type="invalid">
                              Invalid wallet address (won't be saved)
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col xs={5}>
                          <Form.Group
                            className="mb-3"
                            controlId={`formBasicKustomeId`}
                          >
                            <Form.Label>KustomerId</Form.Label>
                            <InputGroup>
                              <Form.Control
                                type="text"
                                readOnly={!kustomerIdEditable}
                                placeholder="Kustomer id"
                                value={user?.systemData?.kustomerId}
                                onChange={handleSystemDataChange}
                                name="kustomerId"
                              />
                              <Button
                                className="me-2"
                                variant="outline-secondary"
                                onClick={() => setKustomerIdEditable(true)}
                              >
                                Edytuj
                              </Button>
                              {user?.systemData?.kustomerId ? (
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={`https://heyedu.kustomerapp.com/app/customers/${user?.systemData?.kustomerId}`}
                                >
                                  <Button variant="outline-info">
                                    Przejdź do kustomera
                                  </Button>
                                </a>
                              ) : (
                                <></>
                              )}
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={4}>
                          <GenesisCounter userId={user!.id}/>
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="systemData">
                    <Accordion.Header>User</Accordion.Header>
                    <Accordion.Body>
                      <Row>
                        <Col xs={4}>
                          <Form.Group
                            className="mb-3"
                            controlId={`formBasicName`}
                          >
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder={"Name"}
                              value={user?.systemData?.name}
                              onChange={handleSystemDataChange}
                              name="name"
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={4}>
                          <Form.Group
                            className="mb-3"
                            controlId={`formBasicSurname`}
                          >
                            <Form.Label>Surname</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder={"Surname"}
                              value={user?.systemData?.surname}
                              onChange={handleSystemDataChange}
                              name="surname"
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={4}>
                          <Form.Group
                            className="mb-3"
                            controlId={`formBasicEmail`}
                          >
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Email"
                              value={user?.email}
                              onChange={handleChange}
                              name="email"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={4}>
                          <Form.Group>
                            <Form.Label>Subscription started</Form.Label>
                            {user?.payment?.subscriptionStarted ? (
                              <>
                                <Form.Control
                                  type="date"
                                  value={
                                    new Date(user?.payment?.subscriptionStarted)
                                      .toISOString()
                                      .split("T")[0]
                                  }
                                  onChange={handlePaymentDatesChange}
                                  name="subscriptionStarted"
                                />
                              </>
                            ) : (
                              <>
                                <Form.Control
                                  type="date"
                                  onChange={handlePaymentDatesChange}
                                  value={undefined}
                                  name="subscriptionStarted"
                                />
                              </>
                            )}
                          </Form.Group>
                        </Col>
                        <Col xs={4}>
                          <Form.Group>
                            <Form.Label>Paid until</Form.Label>
                            {user?.payment?.paidUntil ? (
                              <>
                                <Form.Control
                                  type="date"
                                  value={
                                    new Date(user?.payment?.paidUntil)
                                      .toISOString()
                                      .split("T")[0]
                                  }
                                  onChange={handlePaymentDatesChange}
                                  name="paidUntil"
                                />
                              </>
                            ) : (
                              <>
                                <Form.Control
                                  type="date"
                                  onChange={handlePaymentDatesChange}
                                  value={undefined}
                                  name="paidUntil"
                                />
                              </>
                            )}
                          </Form.Group>
                        </Col>
                        <Col xs={2}>
                          <Form.Group
                            className="mb-3"
                            controlId={`formBasicSubscribed`}
                          >
                            <Form.Label>Subscribed</Form.Label>
                            <Form.Check
                              type="switch"
                              checked={!!user?.payment?.subscribed}
                              onChange={handleSubscribedChange}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={4}>
                          <Row>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicPaymentDetails"
                            >
                              <Form.Label>Payment details</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Payment details"
                                value={user?.payment.paymentDetails}
                                onChange={handlePaymentChange}
                                name="paymentDetails"
                              />
                            </Form.Group>
                          </Row>
                          <Row>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicPaymentDetails"
                            >
                              <Form.Label>Trial?</Form.Label>
                              {user?.payment?.trialEndAt ? (
                                <>
                                  <Form.Control
                                    style={{ border: "1px solid red" }}
                                    type="date"
                                    value={
                                      new Date(user?.payment?.trialEndAt)
                                        .toISOString()
                                        .split("T")[0]
                                    }
                                    disabled
                                  />
                                </>
                              ) : (
                                <>
                                  <Form.Control
                                    type="text"
                                    value={undefined}
                                    disabled
                                  />
                                </>
                              )}
                            </Form.Group>
                          </Row>
                          <Row>
                            <Col xs={4}>
                              <Form.Group
                                className="mb-3"
                                controlId={`formBasicStudentActivityStatus`}
                              >
                                <Form.Label>Student Activity status</Form.Label>
                                <Form.Select
                                  onChange={handleStudentActivityStatusChange}
                                  defaultValue={user?.studentActivityStatus}
                                >
                                  {Object.values(StudentActivityStatus).map(
                                    (x) => {
                                      return (
                                        <option key={x} value={x}>
                                          {x}
                                        </option>
                                      );
                                    }
                                  )}
                                </Form.Select>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={4}>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicStatus"
                          >
                            <Form.Label>Status</Form.Label>
                            <Form.Control
                              className={styles.line3TextArea}
                              type="text"
                              as="textarea"
                              placeholder={"Status"}
                              value={user?.systemData?.status}
                              onChange={handleSystemDataChange}
                              name="status"
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={4}>
                          <Row>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicSubscriptionId"
                            >
                              <Form.Label>SubscriptionId</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder={"Subscription_id"}
                                value={user?.subscriptionId}
                                onChange={handleChange}
                                name="subscriptionId"
                              />
                            </Form.Group>
                          </Row>
                          <Row>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicSelectedLanguage"
                            >
                              <Form.Label>Selected lang</Form.Label>
                              <Form.Control
                                type="text"
                                value={user?.metadata?.selectedLanguage}
                                disabled
                              />
                            </Form.Group>
                          </Row>
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="gameData">
                    <Accordion.Header>Game data</Accordion.Header>
                    <Accordion.Body>
                      <Row>
                        <Col xs={4}>
                          <Form.Group
                            className="mb-3"
                            controlId={`formBasicHeroLevel`}
                          >
                            <Form.Label>Hero Level</Form.Label>
                            <Form.Control
                              type="number"
                              readOnly
                              value={user?.gameData?.heroLevel}
                              name="heroLevel"
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={4}>
                          <Form.Group
                            className="mb-3"
                            controlId={`formBasicDiscordId`}
                          >
                            {user?.discordId ? (
                              <Form.Label>Discord</Form.Label>
                            ) : (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="button-tooltip">
                                    Discord not connected.
                                  </Tooltip>
                                }
                              >
                                <Form.Label>
                                  Discord{" "}
                                  <i className="bi bi-exclamation-triangle"></i>
                                </Form.Label>
                              </OverlayTrigger>
                            )}

                            <InputGroup>
                              <Form.Control
                                disabled
                                type="text"
                                placeholder={"Discord"}
                                value={user?.discordUsername ?? ""}
                              />
                              <ActionButton
                                className="me-2"
                                variant="outline-secondary"
                                color="success"
                                action={loadDiscordUsername}
                              >
                                <i className="bi bi-arrow-clockwise" /> Refresh
                              </ActionButton>
                              <Button
                                className="me-2"
                                variant="outline-secondary"
                                color="danger"
                                onClick={disconnectDiscord}
                              >
                                <i className="bi bi-box-arrow-right" />{" "}
                                Disconnect
                              </Button>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col xs={4}>
                          <Form.Group>
                            <Form.Label>Number of opened tasks</Form.Label>
                            <Form.Control
                              readOnly
                              type="text"
                              placeholder=""
                              value={user?.gameData?.openedTasks}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={4}>
                          <Form.Group
                            className="mb-3"
                            controlId={`formBasicExpToLevelUp`}
                          >
                            <Form.Label>Points for Level Up</Form.Label>
                            <Form.Control
                              type="number"
                              readOnly
                              value={user?.gameData?.expToLevelUp}
                              onChange={handleGameDataChange}
                              name="expToLevelUp"
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={4}>
                          <Form.Group
                            className="mb-3"
                            controlId={`formBasicSkills`}
                          >
                            <Form.Label>
                              <span>Skills</span>
                            </Form.Label>
                            <div>
                              {user?.gameData?.skills ? (
                                user?.gameData?.skills.map((skill, i) => (
                                  <span className="pe-1 mt-1" key={i}>
                                    <OverlayTrigger
                                      trigger="click"
                                      placement="top"
                                      overlay={modifyRemoveSkillsPopover(skill)}
                                      show={
                                        showRemoveSkillPopup[
                                          skill.fullPathId
                                        ] ?? false
                                      }
                                    >
                                      <Button
                                        className="pt-1 mt-1"
                                        variant="dark"
                                        onClick={() =>
                                          setShowRemoveSkillPopup({
                                            ...showRemoveSkillPopup,
                                            [skill.fullPathId]:
                                              !showRemoveSkillPopup[
                                                skill.fullPathId
                                              ],
                                          })
                                        }
                                      >
                                        {skill.displayName}
                                      </Button>
                                    </OverlayTrigger>
                                  </span>
                                ))
                              ) : (
                                <></>
                              )}
                            </div>
                          </Form.Group>
                        </Col>
                        <Col xs={4}>
                          <Form.Group>
                            <Form.Label>
                              <span>Your worlds</span>
                              <OverlayTrigger
                                trigger="click"
                                placement="top"
                                overlay={addWorldsPopover()}
                                show={showAddWorldPopup}
                              >
                                <Button
                                  className="btn btn-light p-0"
                                  disabled={isAddNewWorldDisabled}
                                  onClick={() =>
                                    setShowAddWorldPopup(!showAddWorldPopup)
                                  }
                                >
                                  <i className="bi bi-plus-square-dotted"></i>
                                </Button>
                              </OverlayTrigger>
                            </Form.Label>
                            <div>
                              {user?.gameData?.worlds ? (
                                user?.gameData?.worlds.map((w, i) => (
                                  <span className="pe-1" key={i}>
                                    {w.displayName}{" "}
                                    <Button
                                      className="btn btn-light p-0"
                                      onClick={() => removeWorld(w.worldName)}
                                    >
                                      <i className="bi bi-dash-square-dotted"></i>
                                    </Button>
                                  </span>
                                ))
                              ) : (
                                <></>
                              )}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={4}>
                          <FormGroup>
                            <Form.Label>Points</Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                readOnly
                                type="number"
                                value={user?.gameData?.exp}
                                onChange={handleGameDataChange}
                                name="exp"
                              />
                              <OverlayTrigger
                                trigger="click"
                                placement="top"
                                overlay={addPointsPopover()}
                                show={showAddPointsPopup}
                              >
                                <Button
                                  onClick={() => {
                                    setShowAddPointsPopup(!showAddPointsPopup);
                                    setTimeout(() => {
                                      addPointsPopupInputRef.current?.focus();
                                    }, 0);
                                  }}
                                  variant="outline-secondary"
                                >
                                  +
                                </Button>
                              </OverlayTrigger>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col xs={4}>
                          <Form.Group>
                            <Form.Label>
                              <span>Active paths</span>
                              <AddPathPopover
                                externalSetLoader={setIsLoading}
                                user={user!}
                                setUser={setUser}
                              />
                              <span>
                                <Button
                                  className="btn btn-light p-0"
                                  onClick={recalulateAutogeneratedData}
                                >
                                  <i className="bi bi-arrow-clockwise"></i>
                                </Button>
                              </span>
                            </Form.Label>
                            <div>
                              {user?.gameData?.activePaths ? (
                                user?.gameData?.activePaths.map((ap, i) => (
                                  <span className="pe-1" key={i}>
                                    <ModifyPathPopover
                                      fullActivePathId={ap.fullActivePathId}
                                      displayName={ap.displayName}
                                      user={user}
                                      setUser={setUser}
                                      externalSetLoader={setIsLoading}
                                    />
                                  </span>
                                ))
                              ) : (
                                <></>
                              )}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="worlds">
                    <Accordion.Header>Worlds</Accordion.Header>
                    <Accordion.Body>
                      <Accordion alwaysOpen>
                        <Accordion.Item eventKey="crypto">
                          <Accordion.Header>
                            <span className="text-uppercase">Crypto</span>
                          </Accordion.Header>
                          <Accordion.Body>
                            <Row>
                              <Col xs={4}>
                                <Form.Group
                                  className="mb-3"
                                  controlId={`formBasicCryptoGoal`}
                                >
                                  <Form.Label>Crypto Goal</Form.Label>
                                  <Form.Control
                                    className={styles.line4TextArea}
                                    type="text"
                                    as="textarea"
                                    placeholder={"Crypto Goal"}
                                    value={user?.worlds?.cryptoWorld?.goal}
                                    onChange={handleCryptoWorldChange}
                                    name="goal"
                                  />
                                </Form.Group>
                              </Col>
                              <Col xs={8}>
                                <Row>
                                  <Col xs={12}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId={`formBasicEthWallet`}
                                    >
                                      <Form.Label>Wallet ETH</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder={"Wallet ETH"}
                                        value={user?.ethWallet}
                                        onChange={handleChange}
                                        name="ethWallet"
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId={`formBasicCryptoHardwareWallet`}
                                    >
                                      <Form.Label>Hardware Wallet</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder={"Hardware Wallet"}
                                        value={
                                          user?.worlds?.cryptoWorld
                                            ?.hardwareWallet
                                        }
                                        onChange={handleCryptoWorldChange}
                                        name="hardwareWallet"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col xs={6}>
                                    <Form.Group
                                      className="mb-3"
                                      controlId={`formBasicCryptoSolWallet`}
                                    >
                                      <Form.Label>Wallet SOL</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder={"Wallet SOL"}
                                        value={
                                          user?.worlds?.cryptoWorld?.solWallet
                                        }
                                        onChange={handleCryptoWorldChange}
                                        name="solWallet"
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="side-hustle">
                          <Accordion.Header className="text-uppercase">
                            <span className="text-uppercase">Side hustle</span>
                          </Accordion.Header>
                          <Accordion.Body>
                            <Row>
                              <Col xs={4}>
                                <Form.Group
                                  className="mb-3"
                                  controlId={`formBasicSHWGoal`}
                                >
                                  <Form.Label>Side Hustle Goal</Form.Label>
                                  <Form.Control
                                    className={styles.line4TextArea}
                                    type="text"
                                    as="textarea"
                                    placeholder={"Side Hustle Goal"}
                                    value={user?.worlds?.sideHustleWorld?.goal}
                                    onChange={handleSideHustleWorldChange}
                                    name="goal"
                                  />
                                </Form.Group>
                              </Col>
                              <Col xs={4}>
                                <Form.Group
                                  className="mb-3"
                                  controlId={`formBasicSHWidealCustomer`}
                                >
                                  <Form.Label>Ideal Customer</Form.Label>
                                  <Form.Control
                                    className={styles.line4TextArea}
                                    type="text"
                                    as="textarea"
                                    placeholder={"Ideal Customer"}
                                    value={
                                      user?.worlds?.sideHustleWorld
                                        ?.idealCustomer
                                    }
                                    onChange={handleSideHustleWorldChange}
                                    name="idealCustomer"
                                  />
                                </Form.Group>
                              </Col>
                              <Col xs={4}>
                                <Form.Group
                                  className="mb-3"
                                  controlId={`formBasicSHWproduct`}
                                >
                                  <Form.Label>Product</Form.Label>
                                  <Form.Control
                                    className={styles.line4TextArea}
                                    type="text"
                                    as="textarea"
                                    placeholder={"Product"}
                                    value={
                                      user?.worlds?.sideHustleWorld?.product
                                    }
                                    onChange={handleSideHustleWorldChange}
                                    name="product"
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={4}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasigSHMailingSystem"
                                >
                                  <Form.Label>Mailing system</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Mailing system"
                                    value={
                                      user?.worlds?.sideHustleWorld
                                        ?.mailingSystem
                                    }
                                    onChange={handleSideHustleWorldChange}
                                    name="mailingSystem"
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="personal-finance">
                          <Accordion.Header className="text-uppercase">
                            <span className="text-uppercase">
                              Personal finance
                            </span>
                          </Accordion.Header>
                          <Accordion.Body>
                            <Row>
                              <Col xs={4}>
                                <Form.Group
                                  className="mb-3"
                                  controlId={`formBasicSHWGoal`}
                                >
                                  <Form.Label>Personal finance Goal</Form.Label>
                                  <Form.Control
                                    className={styles.line4TextArea}
                                    type="text"
                                    as="textarea"
                                    placeholder={"Personal finance Goal"}
                                    value={
                                      user?.worlds?.personalFinanceWorld?.goal
                                    }
                                    onChange={handlePersonalFinanceWorldChange}
                                    name="goal"
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="technical-personal-brand">
                          <Accordion.Header className="text-uppercase">
                            <span className="text-uppercase">
                              Technical personal brand
                            </span>
                          </Accordion.Header>
                          <Accordion.Body>
                            <Row>
                              <Col xs={4}>
                                <Form.Group
                                  className="mb-3"
                                  controlId={`formBasicTPBGoal`}
                                >
                                  <Form.Label>Goal</Form.Label>
                                  <Form.Control
                                    className={styles.line4TextArea}
                                    type="text"
                                    as="textarea"
                                    placeholder={
                                      "Technical personal brand Goal"
                                    }
                                    value={
                                      user?.worlds?.technicalPersonalBrand?.goal
                                    }
                                    onChange={
                                      handleTechnicalPersonalBrandWorldChange
                                    }
                                    name="goal"
                                  />
                                </Form.Group>
                              </Col>
                              <Col xs={4}>
                                <Form.Group
                                  className="mb-3"
                                  controlId={`formBasicTPBBrandArea`}
                                >
                                  <Form.Label>Brand area</Form.Label>
                                  <Form.Control
                                    className={styles.line4TextArea}
                                    type="text"
                                    as="textarea"
                                    placeholder={"Brand area"}
                                    value={
                                      user?.worlds?.technicalPersonalBrand
                                        ?.brandArea
                                    }
                                    onChange={
                                      handleTechnicalPersonalBrandWorldChange
                                    }
                                    name="brandArea"
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="copywriting">
                          <Accordion.Header className="text-uppercase">
                            <span className="text-uppercase">Copywriting</span>
                          </Accordion.Header>
                          <Accordion.Body>
                            <Row>
                              <Col xs={4}>
                                <Form.Group
                                  className="mb-3"
                                  controlId={`formBasicCPWGoal`}
                                >
                                  <Form.Label>Goal</Form.Label>
                                  <Form.Control
                                    className={styles.line4TextArea}
                                    type="text"
                                    as="textarea"
                                    placeholder={"Copywriting Goal"}
                                    value={user?.worlds?.copywriting?.goal}
                                    onChange={handleCopywritingWorldChange}
                                    name="goal"
                                  />
                                </Form.Group>
                              </Col>
                              <Col xs={4}>
                                <Form.Group
                                  className="mb-3"
                                  controlId={`formBasicCPWSocialMedia`}
                                >
                                  <Form.Label>Social media</Form.Label>
                                  <Form.Control
                                    className={styles.line4TextArea}
                                    type="text"
                                    as="textarea"
                                    placeholder={"Social media"}
                                    value={
                                      user?.worlds?.copywriting?.socialMedia
                                    }
                                    onChange={handleCopywritingWorldChange}
                                    name="socialMedia"
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="productivity">
                          <Accordion.Header className="text-uppercase">
                            <span className="text-uppercase">Productivity</span>
                          </Accordion.Header>
                          <Accordion.Body>
                            <Row>
                              <Col xs={4}>
                                <Form.Group
                                  className="mb-3"
                                  controlId={`formBasicCPWGoal`}
                                >
                                  <Form.Label>Goal</Form.Label>
                                  <Form.Control
                                    className={styles.line4TextArea}
                                    type="text"
                                    as="textarea"
                                    placeholder={"Productivity Goal"}
                                    value={user?.worlds?.productivity?.goal}
                                    onChange={handleProductivityWorldChange}
                                    name="goal"
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="aiHackers">
                          <Accordion.Header className="text-uppercase">
                            <span className="text-uppercase">AI Hackers</span>
                          </Accordion.Header>
                          <Accordion.Body>
                            <Row>
                              <Col xs={4}>
                                <Form.Group
                                  className="mb-3"
                                  controlId={`formBasicaihackersGoal`}
                                >
                                  <Form.Label>Goal</Form.Label>
                                  <Form.Control
                                    className={styles.line4TextArea}
                                    type="text"
                                    as="textarea"
                                    placeholder={"AI Hackers Goal"}
                                    value={user?.worlds?.aiHackers?.goal}
                                    onChange={handleAiHackersWorldChange}
                                    name="goal"
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="metadata">
                    <Accordion.Header>Metadata</Accordion.Header>
                    <Accordion.Body>
                      <Row>
                        <Col xs={4}>
                          <Form.Group
                            className="mb-3"
                            controlId={`formBasicGender`}
                          >
                            <Form.Label>Gender</Form.Label>
                            <Form.Select
                              onChange={handleSystemDataGenderChange}
                              name="gender"
                              defaultValue={user?.systemData?.gender}
                            >
                              <option value={`don't want to tell`}>
                                don't want to tell
                              </option>
                              <option value="M">Male</option>
                              <option value="F">Female</option>
                              <option value="O">Other</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col xs={4}>
                          <Form.Group
                            className="mb-3"
                            controlId={`formBasicbirthday`}
                          >
                            <Form.Label>Birthday</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Birthday"
                              value={user?.metadata?.birthday}
                              onChange={handleMetadataChange}
                              name="birthday"
                            />
                            {/* {
                                user?.metadata?.birthday ?
                                  (
                                    <>
                                      <Form.Control type="date" value={new Date(user?.metadata?.birthday).toISOString().split('T')[0]} onChange={handleDOBChange} name='birthday' />
                                    </>
                                  ) :
                                  (
                                    <>
                                      <Form.Control type="date" value={new Date().toISOString().split('T')[0]} onChange={handleDOBChange} name='birthday' />
                                    </>
                                  )
                              } */}
                          </Form.Group>
                        </Col>
                        <Col xs={4}>
                          <Form.Group
                            className="mb-3"
                            controlId={`formBasicCity`}
                          >
                            <Form.Label>City</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder={"City"}
                              value={user?.metadata?.city}
                              onChange={handleMetadataChange}
                              name="city"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={4}>
                          <Form.Group
                            className="mb-3"
                            controlId={`formBasicexpirience`}
                          >
                            <Form.Label>Experience</Form.Label>
                            <Form.Control
                              className={styles.line5TextArea}
                              type="text"
                              as="textarea"
                              placeholder={"Experience"}
                              value={user?.metadata?.expirience}
                              onChange={handleMetadataChange}
                              name="expirience"
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={4}>
                          <Form.Group
                            className="mb-3"
                            controlId={`formBasicsentiments`}
                          >
                            <Form.Label>Sentiments</Form.Label>
                            <Form.Control
                              className={styles.line5TextArea}
                              type="text"
                              as="textarea"
                              placeholder={"Sentiments"}
                              value={user?.metadata?.sentiments}
                              onChange={handleMetadataChange}
                              name="sentiments"
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={4}>
                          <Form.Group
                            className="mb-3"
                            controlId={`formBasicgallup`}
                          >
                            <Form.Label>Gallup</Form.Label>
                            {/* <Form.Select multiple onChange={handleGameDataSelectChange} name='gallup'>
                                <option value='Gallup-1'>Gallup-1</option>
                                <option value='Gallup-2'>Gallup-2</option>
                                <option value='Gallup-3'>Gallup-3</option>
                              </Form.Select> */}
                            <Form.Control
                              className={styles.line5TextArea}
                              type="text"
                              as="textarea"
                              placeholder="Gallup"
                              value={user?.metadata?.gallup?.join("\n")}
                              onChange={handleGallupChange}
                              name="gallup"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={4}>
                          <Form.Group
                            className="mb-3"
                            controlId={`formBasicdilemmas`}
                          >
                            <Form.Label>Dilemmas</Form.Label>
                            <Form.Control
                              type="text"
                              as="textarea"
                              placeholder={"Dilemmas"}
                              value={user?.metadata?.dilemmas}
                              onChange={handleMetadataChange}
                              name="dilemmas"
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={4}>
                          <Form.Group
                            className="mb-3"
                            controlId={`formBasiccommunities`}
                          >
                            <Form.Label>Communities</Form.Label>
                            <Form.Select
                              multiple
                              onChange={handleMetadataSelectChange}
                              name="communities"
                              defaultValue={user?.metadata?.communities}
                            >
                              <option value="fs">FireStarters</option>
                              <option value="cg">CGC</option>
                              <option value="he">HeyEdu Founders</option>
                              <option value="ak">Akademia</option>
                              <option value="tp">Transparent Powers</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col xs={4}>
                          <Form.Group
                            className="mb-3"
                            controlId={`formBasicGoals`}
                          >
                            <Form.Label>Goals</Form.Label>
                            <Form.Control
                              type="text"
                              as="textarea"
                              placeholder="Goals"
                              value={user?.systemData?.goals?.join("\n")}
                              onChange={handleGoalsChange}
                              name="goals"
                            />
                            {/* <Form.Select multiple onChange={handleSystemDataSelectChange} name='goals'>
                                <option value='goal1'>Goal1</option>
                                <option value='goal2'>Goal2</option>
                                <option value='goal3'>Goal3</option>
                              </Form.Select> */}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={4}>
                          <Form.Group
                            className="mb-3"
                            controlId={`formBasicprefferedLearning`}
                          >
                            <Form.Label>Preferred Learning</Form.Label>
                            <Form.Control
                              type="text"
                              as="textarea"
                              placeholder={"Preferred Learning"}
                              value={user?.metadata?.prefferedLearning}
                              onChange={handleMetadataChange}
                              name="prefferedLearning"
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={4}>
                          <Form.Group
                            className="mb-3"
                            controlId={`formBasiccontentLanguagePreference`}
                          >
                            <Form.Label>Content Language Preference</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder={"Content Language Preference"}
                              value={user?.metadata?.contentLanguagePreference}
                              onChange={handleMetadataChange}
                              name="contentLanguagePreference"
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={4}>
                          <Form.Group
                            className="mb-3"
                            controlId={`formBasicprefferedEmailHours`}
                          >
                            <Form.Label>
                              Preferred e-mail hour (ex: 16:00,18:00,20:00)
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder={"Preferred e-mail hour"}
                              value={user?.metadata?.prefferedEmailHours}
                              onChange={handlePrefferedHoursChange}
                              name="prefferedEmailHours"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={4}>
                          <Form.Group
                            className="mb-3"
                            controlId={`formBasicAssistantName`}
                          >
                            <Form.Label>Assistant name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Assistant name"
                              value={user?.metadata?.assistantName}
                              onChange={handleMetadataChange}
                              name="assistantName"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="tasks">
                    <Accordion.Header>Tasks</Accordion.Header>
                    <Accordion.Body>
                      <Row>
                        <Col xs={6}>
                          <Form.Group
                            className="mb-3"
                            controlId={`formBasicFinishedTasks`}
                          >
                            <Form.Label>Finished tasks</Form.Label>
                            <Form.Select
                              onChange={(event) =>
                                console.log(event.target.value)
                              }
                              name="finishedTasks"
                              ref={finishedTasksSelectRef}
                            >
                              {user?.gameData?.finishedTasks?.map((task) => (
                                <option key={task} value={task}>
                                  {task}
                                </option>
                              ))}
                            </Form.Select>
                            <Button
                              variant="outline-info"
                              onClick={async () =>
                                await resendTaskHandler(finishedTasksSelectRef)
                              }
                            >
                              Re-send task
                            </Button>
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group
                            className="mb-3"
                            controlId={`formBasicTasksInProgress`}
                          >
                            <Form.Label>Tasks in progress</Form.Label>
                            <Form.Select
                              onChange={(event) =>
                                console.log(event.target.value)
                              }
                              name="tasksInProgress"
                              ref={tasksInProgressSelectRef}
                            >
                              {user?.gameData?.tasksInProgress?.map((task) => (
                                <option key={task} value={task}>
                                  {task}
                                </option>
                              ))}
                            </Form.Select>
                            <Button
                              variant="outline-info"
                              onClick={async () =>
                                await resendTaskHandler(
                                  tasksInProgressSelectRef
                                )
                              }
                              className={"me-3"}
                            >
                              Re-send task
                            </Button>
                            <Button
                              variant="outline-warning"
                              onClick={async () => {
                                await removeTaskHandler(
                                  tasksInProgressSelectRef
                                );
                              }}
                            >
                              Delete task
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={{ span: 6, offset: 6 }}>
                          <AddTaskToInProgress
                            beforeTaskAdded={() => setIsLoading(true)}
                            onTaskAdded={async () => {
                              const fetchedUser = await getUser({ id });
                              setUser(fetchedUser);
                              setIsLoading(false);
                              toast(`Zadanie dodane do użytkownika`, {
                                type: "info",
                                position: "top-center",
                                theme: "colored",
                                hideProgressBar: false,
                                autoClose: 3000,
                              });
                            }}
                            user={user!}
                            onTaskAddFailed={() => {
                              setIsLoading(false);
                            }}
                          />
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="edu">
                    <Accordion.Header>EDU</Accordion.Header>
                    <Accordion.Body>
                      <Row>
                        <Col xs={3}>
                          <Row>
                            <Col>
                              <Form.Group className="mb-3">
                                <Form.Label>Edu balance</Form.Label>
                                <Form.Control
                                  type="number"
                                  readOnly
                                  value={user?.eduBalance}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <AddEduRecord
                              userId={user!.id}
                              setUser={setUser}
                              setLoader={setIsLoading}
                            />
                          </Row>
                        </Col>
                        <Col xs={9}>
                          <List
                            style={{ maxHeight: "450px", overflow: "auto" }}
                            size={"small"}
                            bordered
                            dataSource={user?.eduRewards}
                            renderItem={(item) => (
                              <List.Item>
                                <>
                                  {new Date(item.when).toLocaleString()} -{" "}
                                  {item.title};{" "}
                                  {item.description
                                    ? item.description
                                    : "NO DESCRIPTION"}{" "}
                                  ({item.amount} EDU)
                                </>
                              </List.Item>
                            )}
                          />
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Form>
            </Col>
          </Row>
          <Row>
            <BsButton onClick={saveChanges}>Save changes</BsButton>
          </Row>
        </>
      )}
    </>
  );
};
