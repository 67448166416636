import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "../store";
import User from "../../types/sls/User";
import { SLS_API_URL, SLS_USER_ENDPOINTS } from "../../constants/apiEndpoints";

interface Thunk3rdArg {
  dispatch: AppDispatch;
  state: RootState;
}

export const logOut = createAsyncThunk<void, void, Thunk3rdArg>(
  "logout",
  async (_, { dispatch }) => {
    dispatch(reset());
  }
);

export const loginUser = createAsyncThunk<
  { user: User | null },
  {
    getAccessTokenSilently(): Promise<string>;
  },
  Thunk3rdArg
>(
  `${SLS_USER_ENDPOINTS.GET_USER}/login`,
  async ({ getAccessTokenSilently }) => {
    const token = await getAccessTokenSilently();

    //weird hack to follow cors
    //and be able to go with custom headers
    //... on localhost...
    const url = `${SLS_API_URL}${SLS_USER_ENDPOINTS.GET_USER("")}`;
    const request = new Request(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      mode: "cors",
    });
    const responseUser = await fetch(request);
    if (responseUser.status === 401) {
      return {
        user: null,
        error: "Unauthorized",
      };
    }
    const body = await responseUser.json();

    return {
      user: body.item,
    };
  }
);

export interface UserState {
  user: User | null;
}

const initialState = (): UserState => {
  const user: User | null = null;

  return {
    user,
  };
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState(),
  reducers: {
    reset: (state) => {
      state.user = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.user = action.payload.user;
    });
  },
});

const { reset } = userSlice.actions;

export default userSlice.reducer;
