import { useEffect, useState } from "react";
import { Alert as BsAlert } from 'react-bootstrap';

interface AlertProps {
  variant: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark',
  disissable: boolean,
  header: string,
  content: string,
  show: boolean
}
export const Alert = ({variant, disissable, header, content, show}: AlertProps) => {
  const [isShown, setShow] = useState(true);
  
  useEffect(() => {
    setShow(show);
  }, [show])

  return (
    <>
    {
      isShown ?  
      <BsAlert variant={variant} onClose={() => setShow(false)} dismissible={disissable} className="mt-3 pb-3">
        <BsAlert.Heading>{header}</BsAlert.Heading>
        <p>
          {content}
        </p>
      </BsAlert> :
      <></>
    }
    </>
  )
}