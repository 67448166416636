import {useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {ROUTE_ADMIN_PANEL} from "../../../constants/routes";
import useSls from "../../../hooks/useSls";
import Alert from "../../Alert";
import BsButton from "../../Common/Button";
import Loader from "../../Loader";
import AccountType from "../../../constants/accountType";

export const AddUser = () => {
  const [user, setUser] = useState<{
    email: string;
    accountType?: AccountType,
    name: string,
  }>({
    email: "",
    accountType: AccountType.basic,
    name: ""
  });
  const { addUser } = useSls();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isWarning, setIsWarning] = useState(false);
  const navigate = useNavigate();

  const handleChange = (event: any) => {
    const value = event.target.value;
    //@ts-ignore
    setUser({
      ...user,
      [event.target.name]: value,
    });
  };

  const saveChanges = async () => {
    if (user) {
      if (!user.email || !user.accountType) {
        setIsWarning(true);
        return;
      }
      setIsLoading(true);
      try {
        const response = await addUser({
          email: user.email,
          accountType: user.accountType,
          name: user.name, 
        });
        if (response.status > 299) {
          setIsError(true);
        } else {
          navigate(`${ROUTE_ADMIN_PANEL}?userAddSuccess=true`);
        }
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <Alert
        disissable
        variant="danger"
        header="Oh no..."
        content="Wystąpił błąd przy dodawaniu usera :( pingnij Krzyśka zeby to naprawil (napisz mu +- o której się to wydarzyło, ułatwi mu to robotę) ;)"
        show={isError}
      />
      <Alert
        disissable
        variant="warning"
        header="No mordo... bez takich"
        content="Wyskakuj z wszystkich potrzenbych danych bo inaczej będzie 🙉 kuku"
        show={isWarning}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Row>
            <Col xs={3}>
              <Link to={ROUTE_ADMIN_PANEL}>
                <BsButton>Go back</BsButton>
              </Link>
            </Col>
          </Row>
          <Row>
            <Form>
              <Form.Group className="mb-3" controlId={`formBasicEthWallet`}>
                <Form.Label>Account type</Form.Label>
                <Form.Select
                  required
                  onChange={handleChange}
                  name="accountType"
                  defaultValue={user.accountType}
                >
                  {
                    Object.keys(AccountType).map(x => {
                      //@ts-ignore
                      return <option key={x} value={AccountType[x]}>{x}</option>
                    })
                  }
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId={`formBasicName`}>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Name"
                  value={user?.name}
                  onChange={handleChange}
                  name="name"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId={`formBasicEmail`}>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Email"
                  value={user?.email}
                  onChange={handleChange}
                  name="email"
                />
              </Form.Group>
            </Form>
          </Row>
          <Row>
            <BsButton onClick={saveChanges}>Save changes</BsButton>
          </Row>
        </>
      )}
    </>
  );
};
