import { useUser } from "../../../hooks/useUser";
import { Navigate } from "react-router-dom";
import { ROUTE_ADMIN_PANEL } from "../../../constants/routes";

export const PreventIfLoggedIn = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated, user } = useUser();

  if (isAuthenticated && user) {
    return <Navigate to={ROUTE_ADMIN_PANEL} replace />;
  }

  return children;
};
