import { Col, Row } from "react-bootstrap"
import { Link, Outlet } from "react-router-dom"
import { ROUTE_ADMIN_PANEL, ROUTE_ADMIN_PANEL_REPORT_STUDENTS, ROUTE_ADMIN_PANEL_REPORT_REFLINKS } from "../../../constants/routes"
import BsButton from "../../Common/Button"

export const Reports = () => {
  return (
    <>
      <Row className="border-bottom pb-3 pt-3 row">
        <Col xs={3}><Link to={ROUTE_ADMIN_PANEL_REPORT_STUDENTS}><BsButton>Students</BsButton></Link></Col>
        <Col xs={3}><Link to={ROUTE_ADMIN_PANEL_REPORT_REFLINKS}><BsButton>Reflinks</BsButton></Link></Col>
        <Col xs={3}><Link to={ROUTE_ADMIN_PANEL}><BsButton>Return</BsButton></Link></Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Outlet />
        </Col>
      </Row>
    </>
  )
}