import style from './Loader.module.scss';

interface LoaderProps {
    height?: string,
}
export const Loader = ({height = "400px"}:LoaderProps) => {
    return (
        <div className={style.loader} style={{height: height}}>
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    )
}