import { ChangeEvent, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import User from "../../../types/sls/User";
import useSls from "../../../hooks/useSls";
import { Popover, Select } from "antd";
import Loader from "../../Loader";
import Task from "../../../types/sls/Task";
import { useCopyToClipboard } from "../../../hooks/useCopyToClipboard";
import { toast } from "react-toastify";

interface modifyPathPopoverInputModel {
  fullActivePathId: string;
  displayName: string;
  user: User;
  setUser: (user: User) => void;
  externalSetLoader: (isLoading: boolean) => void;
}
const ModifyPathPopover = ({
  fullActivePathId,
  displayName,
  user,
  setUser,
  externalSetLoader,
}: modifyPathPopoverInputModel) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCongratulatoryMessageRequired, setIsCongratulatoryMessageRequired] =
    useState(user?.gameData?.heroLevel && user.gameData.heroLevel >= 5);
  const [congratulatoryMessages, setCongratulatoryMessages] = useState<
    Record<string, string>
  >({});
  const message = congratulatoryMessages[fullActivePathId] ?? "";
  const isCongratulatoryMessageValid = isCongratulatoryMessageRequired
    ? message.length > 0
    : true;
  const { modifyPath, getTasksForPathLevelId, generateAnswerRequestUrl } =
    useSls();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [answerRequestUrl, setAnswerRequestUrl] = useState<string>("");
  const [selectedTaskHeid, setSelectedTaskHeid] = useState<string>("");
  const [_, copyToClipboard] = useCopyToClipboard();
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    if (user?.gameData?.heroLevel && user.gameData.heroLevel >= 5) {
      setIsCongratulatoryMessageRequired(true);
    } else {
      setIsCongratulatoryMessageRequired(false);
    }
  }, [user]);

  // useEffect(() => {
  //   setIsLoading(true);
  //   getTasksForPathLevelId(fullActivePathId, user.id).then((x) => {
  //     setTasks(x);
  //     setSelectedTaskHeid(x.find((x) => x.preselected)?.heid ?? "");
  //     setIsLoading(false);
  //   });
  // }, []);

  const handleMessage = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setCongratulatoryMessages((messages) => ({
      ...messages,
      [fullActivePathId]: e.target.value,
    }));
  };

  const clearCongratulatoryMessage = () => {
    setCongratulatoryMessages((messages) => ({
      ...messages,
      [fullActivePathId]: "",
    }));
  };

  const completePath = async (fullActivePathId: string) => {
    if (!isCongratulatoryMessageValid) {
      return;
    }

    externalSetLoader(true);
    const result = await modifyPath({
      fullPathLevelId: fullActivePathId,
      action: "completePathLevel",
      userId: user!.id,
      message: congratulatoryMessages[fullActivePathId],
    });
    setUser(result);
    externalSetLoader(false);
    clearCongratulatoryMessage();
  };

  const dropOutPath = async (fullActivePathId: string) => {
    externalSetLoader(true);
    const result = await modifyPath({
      fullPathLevelId: fullActivePathId,
      action: "abortPathLevel",
      userId: user!.id,
    });
    setUser(result);
    externalSetLoader(false);
    clearCongratulatoryMessage();
  };

  const handleSelectChange = (value: string) => {
    setSelectedTaskHeid(value);
    setAnswerRequestUrl("");
  };

  const getAnswerRequestUrl = async () => {
    setIsLoading(true);
    const result = await generateAnswerRequestUrl(user!.id, selectedTaskHeid);
    setAnswerRequestUrl(result);
    setIsLoading(false);
  };

  const handleClickOnInput = () => {
    if (answerRequestUrl) {
      copyToClipboard(answerRequestUrl);
      toast("Copied to clipboard", {
        type: "success",
        position: "top-center",
        theme: "colored",
        hideProgressBar: false,
        autoClose: 2000,
      });
    }
  };

  const popoverContent = (
    <>
      {/*<div className="d-flex justify-content-center flex-column mb-3">*/}
      {/*  <Select*/}
      {/*    options={tasks.map((x) => ({ label: x.displayName, value: x.heid }))}*/}
      {/*    style={{ width: "400px" }}*/}
      {/*    onChange={handleSelectChange}*/}
      {/*    defaultValue={tasks.find((x) => x.preselected)?.heid}*/}
      {/*    value={selectedTaskHeid}*/}
      {/*  />*/}
      {/*  <Button*/}
      {/*    onClick={async () => await getAnswerRequestUrl()}*/}
      {/*    className="mt-2"*/}
      {/*    disabled={!selectedTaskHeid}*/}
      {/*  >*/}
      {/*    Get answer request url*/}
      {/*  </Button>*/}
      {/*  <input*/}
      {/*    onClick={handleClickOnInput}*/}
      {/*    className={"mt-1"}*/}
      {/*    value={answerRequestUrl}*/}
      {/*    readOnly*/}
      {/*    placeholder={"Here you will get URL"}*/}
      {/*  />*/}
      {/*</div>*/}
      <hr />
      {isCongratulatoryMessageRequired && (
        <p>
          User has {user?.gameData?.heroLevel} level, so congratulation message
          is required.
        </p>
      )}
      <textarea
        className="form-control mb-3"
        name="message"
        cols={30}
        rows={4}
        placeholder={"Congratulation message used for Automatic Tip Process"}
        value={message}
        onChange={handleMessage}
      />
      <div className="d-flex justify-content-evenly">
        <div>
          <Button
            onClick={() => completePath(fullActivePathId)}
            variant="success"
            disabled={!isCongratulatoryMessageValid}
          >
            Complete
          </Button>
        </div>
        <div>
          <Button
            onClick={() => dropOutPath(fullActivePathId)}
            variant="danger"
          >
            Drop out
          </Button>
        </div>
      </div>
    </>
  );

  return (
    <Popover
      title={`Modify ${displayName}`}
      content={isLoading ? <Loader height={"300px"} /> : popoverContent}
      open={isOpened}
    >
      <Button onClick={() => setIsOpened(!isOpened)} variant="dark">
        {displayName}
      </Button>
    </Popover>
  );
};

export default ModifyPathPopover;
