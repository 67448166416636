import BsButton from "../../../Common/Button";
import LoggedIn from "../LoggedIn/LoggedIn";
import { useUser } from "../../../../hooks/useUser";
import { useAuth0 } from "@auth0/auth0-react";

export const LogInSection = () => {
  const { loginWithRedirect } = useAuth0();
  const { user, isAuthenticated } = useUser();

  return (
    <>
      {isAuthenticated && user && <LoggedIn />}
      {!isAuthenticated && (
        <>
          <div className="float-end d-none d-sm-block">
            <BsButton onClick={() => loginWithRedirect()}>
              <i className="bi bi-wallet pe-2"></i>Login
            </BsButton>
          </div>
          <div className="float-end h3 d-sm-none">
            <div onClick={async () => await loginWithRedirect()}>
              <i className="bi bi-wallet"></i>
            </div>
          </div>
        </>
      )}
    </>
  );
};
