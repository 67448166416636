import {Button} from "react-bootstrap";
import {toast} from "react-toastify";
import User from "../../../types/sls/User";
import {useEffect, useState} from "react";
import useSls from "../../../hooks/useSls";
import {Popover, Tooltip} from "antd";
import {useCopyToClipboard} from "../../../hooks/useCopyToClipboard";
import {AxiosError} from "axios";

interface AddPathPopoverProps{
  externalSetLoader: (isLoading: boolean) => void;
  user: User;
  setUser: (user: User) => void;
}

export const AddPathPopover = ({externalSetLoader, user, setUser}: AddPathPopoverProps) => {
  const {modifyPath} = useSls();
  const [_, copy] = useCopyToClipboard();

  const [availablePaths, setAvailablePaths] = useState<
    {
      displayName: string;
      fullAvailablePathId: string;
      isFromTestWorld: boolean;
      choiceTextPL: string;
    }[]
  >([]);

  useEffect(() => {
    if (user) {
      setAvailablePaths(user.gameData.availablePaths);
    }
  }, [user]);

  const addPath = async (fullActivePathId: string) => {
    try{
      externalSetLoader(true);
      const result = await modifyPath({
        fullPathLevelId: fullActivePathId,
        action: "addPathLevel",
        userId: user!.id,
      });
      setUser(result);
    }catch(e){
      if(e instanceof AxiosError){
        toast(
          <>
            <div>You can't do this... Too many paths opened... </div>
            <div>
              <img width={"250px"} src={"https://i.giphy.com/media/1o1fLSUsrJUiksY7yw/giphy.webp"} />
            </div>
          </>,
          {
            style: {
              minWidth: "300px"
            },
          type: "error",
          position: "top-center",
          theme: "colored",
          hideProgressBar: false,
          autoClose: 2300,
        });
      }
    }finally{
      externalSetLoader(false);
    }
  };

  const popoverContent = (
    <>
      {availablePaths.map((ap, i) => (
        <div key={i} className="mb-1">
          <div className="align-items-center d-flex justify-content-between">
            <span>
              {ap.displayName}
              {ap.choiceTextPL ? (
                <i
                  className="bi bi-clipboard ms-2"
                  onClick={() => {
                    copy(ap.choiceTextPL);
                    toast("Copied to clipboard", {
                      type: "success",
                      position: "top-center",
                      theme: "colored",
                      hideProgressBar: false,
                      autoClose: 2000,
                    });
                  }}
                ></i>
              ) : (
                <></>
              )}
            </span>
            <span>
              {ap.isFromTestWorld ? (
                <Tooltip title={"!!World under test!!"}>
                  <Button
                    variant="warning"
                    onClick={() => addPath(ap.fullAvailablePathId)}
                  >
                    Add
                  </Button>
                </Tooltip>
              ) : (
                <Button onClick={() => addPath(ap.fullAvailablePathId)}>
                  Add
                </Button>
              )}
            </span>
          </div>
        </div>
      ))}
    </>
  )

  return (
    <>
      {availablePaths.length === 0 ? (
        <Tooltip title={"No path available"}>
          <Button
            className="btn btn-light p-0"
          >
            <i className="bi bi-plus-square-dotted"></i>
          </Button>
        </Tooltip>
      ): (
          <Popover
            title={`Add path`}
            trigger={["click"]}
            content={popoverContent}
          >
            <Button className="btn btn-light p-0">
              <i className="bi bi-plus-square-dotted"></i>
            </Button>
          </Popover>
      )}
    </>
  );
}

export default AddPathPopover;
